import { BASE_URL, BASE_URL_FOXBERRY } from "../utils/urls";
import {
  Active_List,
  LOGIN_SUCCESS,
  UPDATE_PROFILE,
  EDIT_USER,
  SETImage1,
  SETImage2,
  SETImage3,
  SETImage4,
} from "../Constant/adminConstant";
import moment from "moment";

export function setActiveList(data) {
  return {
    type: Active_List,
    payload: data,
  };
}
export function setEditUser(data) {
  return {
    type: EDIT_USER,
    payload: data,
  };
}

export const updateProfile = (data) => {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
};

export function getAllDistrictData() {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(BASE_URL + "/distric/getdistriclist", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getQualitycheckerDistrictData(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    return fetch(
      BASE_URL + "/qualitycheckerdistrict/getuserdistrict",
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) return response.json();
        else {
          alert("District Fetch Error !");
        }
      })
      .then((res) => {
        // console.log("QualityChecker District Data", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllTalukaData() {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(BASE_URL + "/taluka/gettalukalist", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getDistrictWiseTalukaData(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("distric_id", data.distric_id);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/taluka/gettalukalist/district", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllgrampanchayatdata() {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    return fetch(BASE_URL + "/location/gettrainingLocationlist", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getTalukaWisePanchayat(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("distric_id", data.distric_id);
    urlencoded.append("taluka_id", data.taluka_id);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      "https://backend-training.foxberry.live/v1/location/gettrainingLocation/list/taluka/district",
      // BASE_URL + "/location/gettrainingLocation/list/taluka/district",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllTrainingModuleData() {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingmodule/gettrainingmodulelist",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllTrainingCategoryData() {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingcategory/gettrainingcategorylist",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function AssignModuleToPanchyat(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("trainingModule_id", data.trainingModule_id);
    urlencoded.append("trainingModuleName", data.trainingModuleName);
    urlencoded.append("grampanchayat_id", data.grampanchayat_id);
    urlencoded.append("grampanchayatName", data.grampanchayatName);
    urlencoded.append("trainingGuideline", data.trainingGuideline);
    urlencoded.append("distric_id", data.distric_id);
    urlencoded.append("districtName", data.districtName);
    urlencoded.append("createdBy", data.createdBy);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingmcgp/inserttrainingmodule/cat/gp",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllAssignedPanchayat() {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingmcgp/trainingmodule/cat/gp/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllTrainers() {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("userType", "trainer");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/user/getuserlist", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);

        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function AssignTrainertoPanchayat(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("trainer_id", data.trainer_id);
    urlencoded.append("trainerName", data.trainerName);
    urlencoded.append("trainerMobile", data.trainerMobile);
    urlencoded.append("trainingModule_id", data.trainingModule_id);
    urlencoded.append("trainingModuleName", data.trainingModuleName);
    urlencoded.append("distric_id", data.distric_id);
    urlencoded.append("districtName", data.districtName);
    urlencoded.append("grampanchayat_id", data.grampanchayat_id);
    urlencoded.append("grampanchayatName", data.grampanchayatName);
    urlencoded.append("assiggnDate", data.assiggnDate);
    urlencoded.append("shift", data.shift);
    urlencoded.append("createdBy", data.createdBy);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/inserttrainingassigntrainer",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllAssignedTrainer(data) {
  // console.log("Calling AssignedTrainer API",data)
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let payload = {
      documentsPerPage: data.documentsPerPage,
      page: data.page,
    };

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/trainingassigntrainerlist",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllAssignedTrainerByTrainerId(data) {
  // console.log("Calling AssignedTrainer API",data)
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let payload = {
      documentsPerPage: data.documentsPerPage,
      page: data.page,
    };

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + `/trainingassigntrainer/trainingassigntrainerlist?trainer_id=${data.trainer_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getModuleWiseTrainerList(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("grampanchayat_id", data.grampanchayat_id);
    urlencoded.append("trainingModule_id", data.trainingModule_id);
    urlencoded.append("documentsPerPage", data ? data.documentsPerPage : 20)
    urlencoded.append("page", data ? data.page : 1)

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/trainingassigntrainer/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getDateWiseTrainerList(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("trainingDate", data.trainingDate);
    urlencoded.append("trainingDateEnd", data.trainingDateEnd);
    urlencoded.append("documentsPerPage", data ? data.documentsPerPage : 20)
    urlencoded.append("page", data ? data.page : 1)

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/trainingassigntrainer/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllUplodedTrainerList(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("documentsPerPage", data ? data.documentsPerPage : 20)
    urlencoded.append("page", data ? data.page : 1)

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY + "/trainingassigntrainer/trainingassigntrainer/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllUplodedTrainerListByTrainerId(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("documentsPerPage", data ? data.documentsPerPage : 20)
    urlencoded.append("page", data ? data.page : 1)
    urlencoded.append("trainer_id", data.trainer_id)

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY + "/trainingassigntrainer/trainingassigntrainer/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getCategories(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainerevidence/trainerevidence/grampanchayat",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function ApproveEvidence(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("status", "approve");
    urlencoded.append("approveRejectUser_id", data.approveRejectUser_id);
    urlencoded.append("approveRejectUserName", data.approveRejectUserName);
    urlencoded.append("updatedBy", data.updatedBy);
    urlencoded.append("trainerEvidence_id", data.trainerEvidence_id);
    urlencoded.append("trainingModule_id", data.trainingModule_id);
    urlencoded.append("grampanchayat_id", data.grampanchayat_id);
    urlencoded.append("trainingCategory_id", data.trainingCategory_id);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainerevidence/trainerevidenceapprovereject",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function RejectEvidence(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("status", "reject");
    urlencoded.append("approveRejectComment", data.approveRejectComment);
    urlencoded.append("approveRejectUser_id", data.approveRejectUser_id);
    urlencoded.append("approveRejectUserName", data.approveRejectUserName);
    urlencoded.append("updatedBy", data.updatedBy);
    urlencoded.append("trainerEvidence_id", data.trainerEvidence_id);
    urlencoded.append("trainingModule_id", data.trainingModule_id);
    urlencoded.append("grampanchayat_id", data.grampanchayat_id);
    urlencoded.append("trainingCategory_id", data.trainingCategory_id);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainerevidence/trainerevidenceapprovereject",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getQualityCheckerList() {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("userType", "qc");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/user/getuserlist", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getDistrictCollectorList() {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("userType", "dc");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/user/getuserlist", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function AssignDistrictToQCData(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(BASE_URL + "/user/assigndistrict", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function FinalAprroveTraining(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/trainerevidence/final/approve",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function FetchApprovedTrainingData(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/training/finalapproval/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function FetchAllApprovedTrainingData(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/training/finalapproval/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function fetchDateWiseApprovedTrainingData(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("startDate", data.trainingDate);
    urlencoded.append("endDate", data.trainingDateEnd);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/training/finalapproval/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getSingleTrainerData(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("user_id", data.user_id);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/user/getusersinglelist", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllDatewiseAssignedTrainer(data) {
  return (dispatch) => {
    let requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    return fetch(
      BASE_URL +
      `/trainingassigntrainer/trainingassigntrainerlist?startDate=${data.start}&endDate=${data.end}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function DistrictWisePanchayat(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(BASE_URL + "/location/gettrainingLocation/list/district", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllDistrictwiseAssignedTrainer(data) {
  return (dispatch) => {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let payload = {
      documentsPerPage: data.documentsPerPage,
      page: data.page,
    };

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    return fetch(
      BASE_URL +
      `/trainingassigntrainer/trainingassigntrainerlist?distric_id=${data.distric_id}&grampanchayat_id=${data.grampanchayat_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllDistrictwiseAssignedTrainer1(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")
    let urlencoded = new URLSearchParams();
    urlencoded.append("trainer_id", data?.trainer_id);
    urlencoded.append("distric_id", data?.distric_id)
    urlencoded.append("location_id", data?.grampanchayat_id)

    let requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: myHeaders,
      body: urlencoded
    };

    return fetch(
      BASE_URL +
      `/trainingassigntrainer/trainingassigntrainer/fortrainer`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllDatewiseAssignedTrainer1(data) {

  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")
    let urlencoded = new URLSearchParams();
    urlencoded.append("trainer_id", data?.trainer_id);
    urlencoded.append("startDate", data?.start)
    urlencoded.append("endDate", data?.end)

    let requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: myHeaders,
      body: urlencoded
    };

    return fetch(
      BASE_URL +
      `/trainingassigntrainer/trainingassigntrainer/fortrainer`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getDashboardCounts() {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(BASE_URL + `/dashboard/dashboardcount`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function updateProfilePassword(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("newPassword", data.newPassword);
    urlencoded.append("oldPassword", data.oldPassword);
    urlencoded.append("user_id", data.user_id);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/user/updateuserpassword", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function UnassignedTrainers(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/training/not/assigntrainerlist",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllCategoryEvidence(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainerevidence/trainerevidence/categories/list",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function ApproveTrainingCategory(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(BASE_URL + "/trainingmcgp/category/approve", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

//QC
export function getDistrictWiseTrainingList(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/districwiselist",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getDistrictWiseApprovalList(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/approvallistdistrictwise",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getDistrictWiseApprovalListForTrainer(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/approvallistdistrictwise/fortrainer",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function BulkTrainingInsert(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/bulkinserttrainingassigntrainer",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function DeleteTrainingRecord(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append(
      "trainingAssignTrainer_id",
      data.trainingAssignTrainer_id
    );
    urlencoded.append("updatedBy", data.updatedBy);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/trainingassigntrainerdelete",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function UnassignTrainer(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append(
      "trainingAssignTrainer_id",
      data.trainingAssignTrainer_id
    );
    urlencoded.append("index", data.index);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/trainingunassigntrainer",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getSingleTraining(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append(
      "trainingAssignTrainer_id",
      data.trainingAssignTrainer_id
    );

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/trainingassigntrainer/singlelist",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function ReAssignTrainerdata(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append(
      "trainingAssignTrainer_id",
      data.trainingAssignTrainer_id
    );
    urlencoded.append("index", data.index);
    urlencoded.append("user_id", data.user_id);
    urlencoded.append("name", data.name);
    urlencoded.append("mobileNo", data.mobileNo);
    urlencoded.append("updatedBy", data.updatedBy);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/assigntrainer",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function ForcefullyReassign(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append(
      "trainingAssignTrainer_id",
      data.trainingAssignTrainer_id
    );
    urlencoded.append("index", data.index);
    urlencoded.append("user_id", data.user_id);
    urlencoded.append("name", data.name);
    urlencoded.append("mobileNo", data.mobileNo);
    urlencoded.append("updatedBy", data.updatedBy);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL + "/trainingassigntrainer/forceassignunassigntrainer",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function fetchBeneficieryList(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append(
      "trainingsssigntrainer_id",
      data.trainingsssigntrainer_id
    );

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY + "/beneficiary/getbeneficiarylist",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function GenerateCert(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("trainingBeneficieryId", data.trainingBeneficieryId);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return (
      fetch(
        BASE_URL_FOXBERRY + "/beneficiary/Beneficiery-certificate/pdf",
        requestOptions
      )
        // return fetch("http://localhost:7000/v1" + '/beneficiary/Beneficiery-certificate/pdf', requestOptions)
        .then((response) => response.json())
        .then((res) => {
          // console.log("API", res);
          return res;
        })
        .catch((error) => {
          console.error("Error:", error);
        })
    );
  };
}

export function GenerRateReport(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(BASE_URL_FOXBERRY + "/report/createreport/pdf", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function GenerateFeedbackReport(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY + "/report/createfeedbackreport/pdf",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function GenerateSummaryReport(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY + "/report/createsummaryreport/pdf",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function TrainerReport(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(BASE_URL_FOXBERRY + "/report/inserreport/info", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function UpdateUserSignature(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    return fetch(BASE_URL_FOXBERRY + "/user/update/signature", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        dispatch(updateProfile(res.user));
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function updateProfilePic(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("profilePhotoURL", data.profilePhotoURL);
    urlencoded.append("user_id", data.user_id);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/user/updateprofilepicture", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        dispatch(updateProfile(res));
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllTrainingData(data) {
  return (dispatch) => {
    console.log(data)
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY +
      `/trainingassigntrainer/trainingassigntrainerlist/all?documentsPerPage=${data.documentsPerPage}&page=${data.page}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllTrainingDataByModule(data) {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY +
      `/trainingassigntrainer/trainingassigntrainerlist/all?location_id=${data.grampanchayat_id}&distric_id=${data.distric_id}&trainingModuleName=${data.trainingModuleName}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function getAllTrainingDataByDate(data) {
  return (dispatch) => {
    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      BASE_URL_FOXBERRY +
      `/trainingassigntrainer/trainingassigntrainerlist/all?startDate=${data.startDate}&endDate=${data.endDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log("API", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export function SendSms(data) {
  return (dispatch) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("trainingBeneficieryId", data);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    return fetch(BASE_URL + "/beneficiary/certificate/sms", requestOptions)
      .then((res) => {
        // console.log("Send Sms API call", res);
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Image1 from "../../Assets/Image/satara_Mandave.JPG";
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg";

const cityData = [
  {
    label: "Akola",
    value: "Akola",
  },
  {
    label: "Pune",
    value: "Pune",
  },
  {
    label: "Beed",
    value: "Beed",
  },
  {
    label: "Kolhapur",
    value: "Kolhapur",
  },
  {
    label: "Mumbai",
    value: "Mumbai",
  },
  {
    label: "Nagpur",
    value: "Nagpur",
  },
  {
    label: "Sangali",
    value: "Sangali",
  },
];

const TalukaData = [
  {
    label: "Ambegoan",
    value: "Ambegoan",
  },
  {
    label: "Baramati",
    value: "Baramati",
  },
  {
    label: "Bhor",
    value: "Bhor",
  },
  {
    label: "Daund",
    value: "Daund",
  },
  {
    label: "Haveli",
    value: "Haveli",
  },
  {
    label: "Khed",
    value: "Khed",
  },
  {
    label: "Mulshi",
    value: "Mulshi",
  },
];

const PanchayatData = [
  {
    label: "ADEGAON",
    value: "ADEGAON",
  },
  {
    label: "AGARGAON",
    value: "AGARGAON",
  },
  {
    label: "BAJARWADA",
    value: "BAJARWADA",
  },
  {
    label: "CHINCHALA",
    value: "CHINCHALA",
  },
  {
    label: "DHANORA",
    value: "DHANORA",
  },
  {
    label: "FARITPUR",
    value: "FARITPUR",
  },
  {
    label: "ITALAPUR",
    value: "ITALAPUR",
  },
];

const TrainerData = [
  {
    Name: "ABC",
    Mobile: "8529637410",
    Email: "ABC@123gmail.com",
    GramPanchyat: "Maval",
    startDate: "20-10-2022",
    Module: "Type_1",
    Address: "Pune",
  },
  {
    Name: "PQR",
    Mobile: "789456130",
    Email: "PQR@123gmail.com",
    GramPanchyat: "Mulshi",
    startDate: "4-10-2022",
    Module: "Type_2",
    Address: "Pune",
  },
  {
    Name: "XYZ",
    Mobile: "3216974036",
    Email: "XYZ@123gmail.com",
    GramPanchyat: "Beed",
    startDate: "2-10-2022",
    Module: "Type_1",
    Address: "Pune",
  },
  {
    Name: "LMN",
    Mobile: "789456130",
    Email: "LMN@123gmail.com",
    GramPanchyat: "Bhugaon",
    startDate: "30-10-2022",
    Module: "Type_2",
    Address: "Pune",
  },
];

const Type = [
  {
    label: "type_1",
    value: "Type_1",
  },
  {
    label: "type_2",
    value: "type_2",
  },
];

export const GrampanchayatDetails = ({
  getAllDistrictData,
  getAllTalukaData,
  getAllgrampanchayatdata,
  userDetails
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  // Getting district and _id 
  const arr = userDetails?.assignDistrictName?.map((item,index)=>{
    return {
      'district':item,
      'district_id':userDetails.assignDistrict_id[index],
    }
  })

  //data variables
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [trainerData, setTrainerData] = useState([]);
  const [type, setType] = useState("");
  const [TalukaData, setTalukaData] = useState([]);
  const [cityData, setcityData] = useState(arr);
  const [PanchayatData, setPanchayatData] = useState([]);
  //Error Variables
  const [cityError, setcityError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateDate = () => {
    let IsformValid = true;

    if (!city) {
      IsformValid = false;
      setcityError(true);
    }
    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }
    if (!panchayat) {
      IsformValid = false;
      setPanchayatError(true);
    }
    // if (!type) {
    //   IsformValid = false;
    //   setTypeError(true);
    // }

    return IsformValid;
  };

  //GET Trianer data
  const getTrainerData = () => {
    setcityError(false);
    setTalukaError(false);
    setPanchayatError(false);
    // setTypeError(false);

    if (validateDate()) {
      // console.log("Setting Trainer data");
      setTrainerData(TrainerData);
    }
  };
  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      // setcityData(result);
    }
  };

  const getTaluka = async () => {
    const result = await getAllTalukaData();

    if (result !== "No data found") {
      setTalukaData(result);
    }
  };
  const getGrampanchayat = async () => {
    const result = await getAllgrampanchayatdata();
    if (result !== "No data found") {
      setPanchayatData(result);
    }
  };
  useEffect(() => {
    getDistrict();
    getTaluka();
    getGrampanchayat();
  }, []);

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          Training Location Details 
        </Typography>
      </center>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 50 }}>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">City</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-Bank"
            label="City"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {cityData &&
              cityData.map((item,index) => {
                return (
                  <MenuItem key={index} value={item.district_id}>
                    {item?.district}
                  </MenuItem>
                );
              })}
          </Select>
          {cityError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select City
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-Bank"
            label="Taluka"
            value={taluka}
            onChange={(e) => {
              setTaluka(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {TalukaData &&
              TalukaData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.talukaName}
                  </MenuItem>
                );
              })}
          </Select>
          {TalukaError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Taluka
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">Training Location</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-month"
            label="Training Location"
            value={panchayat}
            onChange={(e) => {
              setPanchayat(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {PanchayatData &&
              PanchayatData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.locationName}
                  </MenuItem>
                );
              })}
          </Select>
          {PanchayatError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Training Location
            </span>
          ) : null}
        </FormControl>
        {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
          <InputLabel id="outlined-adornment-Bank">Type</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-month"
            label="Type"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {Type &&
              Type.map((item) => {
                return (
                  <MenuItem key={item?.label} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                );
              })}
          </Select>
          {typeError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select Type
            </span>
          ) : null}
        </FormControl> */}
        <Button
          sx={{ m: 1, marginRight: 3 }}
          variant="contained"
          style={{ fontSize: 14 }}
          onClick={() => getTrainerData()}
        >
          Get Data
        </Button>
      </div>
      <Paper sx={{ overflow: "hidden", width: "90%" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>SR No.</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Training Location</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Assignment Date	</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Shift</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Module Description	</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Support Trainer	</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Training Start Date</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Trainer Mobile</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainerData.length > 0 ? (
                trainerData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.Name}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.startDate}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.Email}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.Address}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.GramPanchyat}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.startDate}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                           {row?.Mobile}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate("/district-coordinator/view", {
                                state: row,
                              })
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>NO Data Found</TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={trainerData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
  image: {
    width: 80,
    height: 80,
  },
  imageApprove: {
    width: 80,
    height: 80,
    border: "5px solid green",
  },
  imageReject: {
    width: 80,
    height: 80,
    border: "5px solid red",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getAllTalukaData: () => dispatch(Action.getAllTalukaData()),
    getAllgrampanchayatdata: () => dispatch(Action.getAllgrampanchayatdata()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GrampanchayatDetails);

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, Modal, IconButton, Card, CircularProgress, TextField, } from "@mui/material";
import * as Action from "../../../actions/adminAction";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";

export const BatchesList = ({ setActiveList }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    //data variable
    const [batchesData, setBatchesData] = useState([]);
    //Table Pagination variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [opendeletebatches, setopendeletebatches] = useState();
    const [ViewData, setViewData] = useState(false);
    const [ViewBatches, setViewBatches] = useState(false);
    const [deleteid, setdeleteid] = useState();

    //Table Pagination Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // for get batches data
    const getBatchesList = async () => {
        setLoader(true)
        await axios
            .get(process.env.REACT_APP_BASE_URL + "/batch/getbatchlist")
            .then((response) => {
                console.log("responce from getbatcheslist", response);
                if (response.status === 200) {
                    setBatchesData(response?.data);
                    setLoader(false)
                } else {
                    setBatchesData([]);
                    setLoader(false)
                }
            })
            .catch((err) => {
                console.log("Error:", err);
                setLoader(false)
            });
    };

    const openDelete = (row) => {
        setdeleteid(row._id);
        setopendeletebatches(true);
    };

    const openView = (row) => {
        setViewData(row);
        setViewBatches(true);
    };

    // for delete batches
    const DeleteBatches = async () => {
        const batch_id = deleteid;
        axios
            .put(process.env.REACT_APP_BASE_URL + "/batch/batchdelete", { batch_id })
            .then((response) => {
                getBatchesList()
            })
            .catch((err) => {
                console.log("Error:", err);
            });
    };

    useEffect(() => {
        getBatchesList();
    }, []);

    return (
        <div className={classes.main}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",

                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <h1> Batches List</h1>
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                </Box>
                <Box sx={{ ml: 5 }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate("/admin/add-batches");
                            setActiveList("/admin/add-batches");
                        }}
                    >
                        <AddIcon />
                        Add Batches
                    </Button>
                </Box>
            </div>

            {loader ? <CircularProgress color="primary" /> :
                <div>
                    <Paper sx={{ overflow: "hidden" }}>
                        <TableContainer>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>SR No.</strong>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>Batch Name</strong>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>Batches Code</strong>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>Count</strong>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>Start Date</strong>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>End Date</strong>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>Assign Date</strong>
                                    </TableCell>
                                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                                        <strong>Action</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {batchesData?.length > 0 ? (
                                    batchesData
                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                                                        {row?.batchName}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                                                        {row?.batchCode}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                                                        {row?.batchCount}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                                                        {moment(row?.batchStartDate).format('YYYY-MM-DD')}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                                                        {moment(row?.batchEndDate).format('YYYY-MM-DD')}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                                                        {moment(row?.batchAssessmentDate).format('YYYY-MM-DD')}
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>
                                                        <Button
                                                            variant="contained"
                                                            style={{ margin: 5 }}
                                                            onClick={() => openView(row)}
                                                        >
                                                            <Visibility style={{ marginRight: 5 }} />
                                                            {/* View */}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            style={{ margin: 5 }}
                                                            onClick={() => navigate("/admin/edit-batches", { state: { batchData: row } })}
                                                        >
                                                            <Edit style={{ marginRight: 5 }} />
                                                            {/* Edit */}
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                openDelete(row);
                                                            }}
                                                            variant="contained"
                                                            style={{ margin: 5 }}
                                                        >
                                                            <Delete style={{ marginRight: 5 }} />
                                                            {/* Delete */}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                ) : (
                                    <TableRow>NO Data Found</TableRow>
                                )}
                            </TableBody>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={batchesData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            }

            {/* delete model */}
            <Modal
                className={classes.middlePosition}
                open={opendeletebatches}
                onClose={(e) => {
                    e.preventDefault();
                    setopendeletebatches(false);
                }}
            >
                <Paper className={classes.profilemodal}>
                    <Stack
                        alignItems="center"
                        spacing={2}
                    >
                        <Paper>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Stack direction="column">
                                    <Typography
                                        variant="h6"
                                        justifyContent={"center"}
                                        component="div"
                                    >
                                        Are You Sure You Want To Delete ?
                                    </Typography>
                                </Stack>
                            </Stack>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: "21px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    style={{ margin: 10, backgroundColor: "green" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setopendeletebatches(false);
                                    }}
                                >
                                    Cancle
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ margin: 10, backgroundColor: "red" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        DeleteBatches();
                                        setopendeletebatches(false);
                                    }}
                                >
                                    <Delete style={{ marginRight: 5 }} />
                                    Delete
                                </Button>
                            </div>
                        </Paper>
                    </Stack>
                </Paper>
            </Modal>

            {/* view model */}
            <Modal
                className={classes.middlePosition}
                open={ViewBatches}
                onClose={(e) => {
                    e.preventDefault();
                    setViewBatches(false);
                }}
            >
                <Paper className={classes.profilemodal}>
                    <Stack alignItems="center" spacing={2}>
                        <Stack>
                            <Typography variant="h6" component="div">
                                View Batches
                            </Typography>
                        </Stack>
                        <Stack>
                            <Card
                                className={classes.card}
                                style={{ backgroundColor: "whitesmoke" }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#3A3A56",
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        margin: 0,
                                    }}
                                >
                                    <IconButton style={{ margin: 10, padding: 0 }}>
                                        <Typography
                                            style={{
                                                fontFamily: "sans-serif",
                                                fontWeight: 700,
                                                color: "white",
                                                fontSize: 24,
                                                backgroundColor: "#3A3A56",
                                            }}
                                            sx={{}}
                                        >
                                            Batches Data
                                        </Typography>
                                    </IconButton>
                                </div>
                                <div>
                                    <Table>
                                        <TableRow>
                                            <TableCell style={{ fontSize: 15 }}>Batch Name</TableCell>
                                            <TableCell style={{ fontSize: 15 }}>
                                                {ViewData?.batchName ? ViewData?.batchName : "-"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: 15 }}>
                                                Batch Code
                                            </TableCell>
                                            <TableCell style={{ fontSize: 15 }}>
                                                {ViewData?.batchCode ? ViewData?.batchCode : "-"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: 15 }}>Batch Count</TableCell>
                                            <TableCell style={{ fontSize: 15 }}>
                                                {ViewData?.batchCount ? ViewData?.batchCount : '-'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: 15 }}>
                                                Batch Start Date
                                            </TableCell>
                                            <TableCell style={{ fontSize: 15 }}>
                                                {ViewData?.batchStartDate ? moment(ViewData?.batchStartDate).format('YYYY-MM-DD') : "-"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: 15 }}>
                                                Batch End Date
                                            </TableCell>
                                            <TableCell style={{ fontSize: 15 }}>
                                                {ViewData?.batchEndDate ? moment(ViewData?.batchEndDate).format('YYYY-MM-DD') : "-"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: 15 }}>
                                                Batch Assessment Date
                                            </TableCell>
                                            <TableCell style={{ fontSize: 15 }}>
                                                {ViewData?.batchAssessmentDate ? moment(ViewData?.batchAssessmentDate).format('YYYY-MM-DD') : "-"}
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </div>
                                <center>
                                    <Button
                                        variant="contained"
                                        style={{ margin: 20 }}
                                        onClick={() => setViewBatches(false)}
                                    >
                                        Back
                                    </Button>
                                </center>
                            </Card>
                        </Stack>
                    </Stack>
                </Paper>
            </Modal>

        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: 30,
    },
    middlePosition: {
        marginTop: 100,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    profilemodal: {
        // width: "1000px",
        width: "50%",
        background: "white",
        borderRadius: "20px !important",
        margin: "20px",
        padding: "20px",
    },
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveList: (data) => dispatch(Action.setActiveList(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesList);

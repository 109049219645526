// PaginationComponent.jsx
import React from 'react';
import { Pagination, Box, Button, Select, MenuItem, FormControl, InputLabel, Container } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PaginationComponent = ({ currentPage, totalPages, onPageChange, onDocsPerPageChange, docsPerPage }) => {
  // console.log(currentPage)
  // Logic for rendering pagination buttons
  const pageNumbers = []; // Array to store individual page numbers

  // Generate page numbers based on the total number of pages
  const maxPageButtons = 3; // You can change this value to set the maximum number of page buttons shown

  // Calculate the starting and ending page numbers for pagination
  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  // Adjust the starting and ending page numbers if there are not enough pages to show
  if (endPage - startPage + 1 < maxPageButtons) {
    if (startPage === 1) {
      endPage = Math.min(totalPages, maxPageButtons);
    } else {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }
  }

  // Add the first page number
  if (startPage > 1) {
    pageNumbers.push(1);
  }

  // Add "..." dot if there are pages before the first page shown
  if (startPage > 2) {
    pageNumbers.push("...");
  }

  // Add individual page numbers
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // Add "..." dot if there are pages after the last page shown
  if (endPage < totalPages - 1) {
    pageNumbers.push("...");
  }

  // Add the last page number
  if (endPage < totalPages) {
    pageNumbers.push(totalPages);
  }

  const nextpage = () => {
    onPageChange(currentPage + 1)
    // console.log("Next page",currentPage + 1)
  }

  return (
    <Box sx={{ my: 3 }}>
      <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button 
            disabled={currentPage === 1} 
            onClick={() => onPageChange(currentPage - 1)}
            startIcon={<ChevronLeftIcon />}
          >
            Prev
          </Button>
          
          {pageNumbers.map((pageNumber, index) => (
            <React.Fragment key={index}>
              {pageNumber === "..." ? (
                <Box component="span" sx={{ mx: 1 }}>...</Box>
              ) : (
                <Button
                  variant={pageNumber === currentPage ? 'contained' : 'outlined'}
                  onClick={() => onPageChange(pageNumber)}
                  sx={{ mx: 0.5 }}
                >
                  {pageNumber}
                </Button>
              )}
            </React.Fragment>
          ))}

          <Button 
            disabled={currentPage === totalPages} 
            onClick={() => nextpage()}
            endIcon={<ChevronRightIcon />}
          >
            Next
          </Button>
        </Box>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Rows per page</InputLabel>
          <Select
            value={docsPerPage}
            onChange={(e) => onDocsPerPageChange(e.target.value)}
            label="Rows per page"
          >
            {[50, 100, 200, 400, 600, 1000].map((value) => (
              <MenuItem key={value} value={value}>
                {value}/Page
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
    </Box>
  );
};

export default PaginationComponent;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Grid,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import { BASE_URL } from "../../utils/urls";
import moment from "moment";

export const ConsolidatedTrainerList = ({
  getAllDistrictData,
  getDistrictWiseTalukaData,
  getTalukaWisePanchayat,
  getAllTrainers,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  //data variables
  const [city, setCity] = useState("");
  const [cityData, setcityData] = useState([]);
  const [cityError, setcityError] = useState(false);

  const [userData, setuserData] = useState([]);
  const [searchTrainerError, setsearchTrainerError] = useState(false);
  const [searchTrainer, setsearchTrainer] = useState(null);
  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getTrainers();
    getDistrict();
  }, []);

  useEffect(() => {
    if (searchTrainer) {
      getData();
    }
  }, [searchTrainer,city]);

  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setcityData(result);
    }
  };

  const getTrainers = async () => {
    const result = await getAllTrainers();
    if (result) {
      setuserData(result);
    }
  };

  const getData = async () => {
    setLoader(true);
    let payload = {
      trainer_id: searchTrainer ? searchTrainer._id : "",
      distric_id: city ? city._id : "",
    };

    await fetch(
      BASE_URL + "/trainingassigntrainer/trainerDistrictWiseDocUplodCount",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response !== "No data found") {
          setData(response[0]);
          setLoader(false);
        } else {
          setData([]);
          setLoader(false);
        }
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          Consolidated Trainer wise Report
        </Typography>
      </center>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          marginBottom: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Autocomplete
          id="Trainer-data"
          sx={{ width: 300 }}
          options={userData}
          autoHighlight
          disablePortal
          freeSolo
          disabled={userData === undefined || userData.length === 0}
          getOptionLabel={(option) =>
            option?.firstName +
            " " +
            option?.lastName +
            " - " +
            option?.contactNo
          }
          renderInput={(params) => (
            <TextField {...params} label="Search By Trainer" />
          )}
          // value={searchTrainer}
          onChange={(event, newValue) => {
            setsearchTrainer(newValue);
          }}
        />
        {searchTrainerError ? (
          <span style={{ color: "red" }}>*Please Select trainer first</span>
        ) : null}

        {searchTrainer ? (
          <FormControl sx={{ m: 1, marginRight: 1 }}>
            <InputLabel id="outlined-adornment-Bank">District</InputLabel>
            <Select
              style={{ width: 300 }}
              id="outlined-adornment-Bank"
              label="District"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              disabled={cityData === undefined || cityData.length === 0}
            >
              {cityData &&
                cityData.map((item) => {
                  return (
                    <MenuItem key={item?._id} value={item}>
                      {item?.districtName}
                    </MenuItem>
                  );
                })}
            </Select>
            {cityError ? (
              <span style={{ color: "red", fontSize: 15 }}>
                *Please Select District
              </span>
            ) : null}
          </FormControl>
        ) : null}
      </div>
      {!searchTrainer ? (
        <center>
          <Typography style={{ margin: 20, fontSize: 20, fontWeight: 600 }}>
            Please Select Trainer first to get Data.
          </Typography>
        </center>
      ) : loader ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          <div className={classes.countDiv}>
            <Typography style={{ margin: 20, fontSize: 17, fontWeight: 600 }}>
              Total Training Conducted - {data?.totalGrampanchayats}
            </Typography>

            <Typography style={{ margin: 20, fontSize: 17, fontWeight: 600 }}>
              Total Document Upload - {data?.totalUploadCount}
            </Typography>
          </div>
          <Paper sx={{ overflow: "hidden", width: "90%" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>SR No.</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Trainer Name</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>District</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Taluka</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>GramPanchyat</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Training Module</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Training Start Date</strong>
                    </TableCell>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      <strong>Documents Uploaded</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data !== "No data found" &&
                  data &&
                  data.grampanchayats &&
                  data.grampanchayats.length > 0 ? (
                    data.grampanchayats
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {index + 1 + rowsPerPage * page}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {searchTrainer?.firstName +
                                " " +
                                searchTrainer?.lastName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {row?.districtName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {row?.talukaName ? row?.talukaName : "-"}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {row?.grampanchayatName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {row?.trainingModuleName}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {moment(row?.startDate).format("DD MMM YYYY")}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: 16, textAlign: "center" }}
                            >
                              {row?.docUploadedCount}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>NO Data Found</TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={count ? count : 0}
              count={
                data !== "No data found" && data.grampanchayats
                  ? data?.grampanchayats.length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  countDiv: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
  image: {
    width: 80,
    height: 80,
  },
  imageApprove: {
    width: 80,
    height: 80,
    border: "5px solid green",
  },
  imageReject: {
    width: 80,
    height: 80,
    border: "5px solid red",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    img1: state.admin.img1,
    img2: state.admin.img2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getDistrictWiseTalukaData: (data) =>
      dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) =>
      dispatch(Action.getTalukaWisePanchayat(data)),
    getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
    FetchApprovedTrainingData: (data) =>
      dispatch(Action.FetchApprovedTrainingData(data)),
    FetchAllApprovedTrainingData: () =>
      dispatch(Action.FetchAllApprovedTrainingData()),
    fetchDateWiseApprovedTrainingData: (data) =>
      dispatch(Action.fetchDateWiseApprovedTrainingData(data)),
    getAllTrainers: () => dispatch(Action.getAllTrainers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidatedTrainerList);

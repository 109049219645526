import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Card, CircularProgress } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Image1 from "../../Assets/Image/satara_Mandave.JPG"
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Videos from "../../Assets/Image/Video.png"
import DOC from "../../Assets/Image/doc.png"
import TextsmsIcon from '@mui/icons-material/Textsms';
import axios from "axios";

export const ViewBeneficieryList = ({ userDetails, fetchBeneficieryList, GenerateCert, SendSms }) => {

    const classes = useStyles()
    const location = useLocation()
    const navigate = useNavigate()
    const data = location.state
    // console.log("Training", data)

    const [loader, setLoader] = useState(false)
    const [loaderArr, setLoaderArr] = useState([])
    const [loading, setLoading] = useState(null);

    //data variables
    const [beneficieryDetails, setBeneficieryDetails] = useState([])

    //Table Pagination variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //Table Pagination Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //UseEffects
    useEffect(() => {
        getBenficiery()
    }, [])

    //get Beneficiey
    const getBenficiery = async () => {
        setLoader(true)
        const result = await fetchBeneficieryList({
            trainingsssigntrainer_id: data._id
        })
        if (result !== 'No data found') {
            // console.log("Beneficiries", result)
            setBeneficieryDetails(result)
            let arr = []
            arr.length = result.length
            setLoaderArr(arr)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    const getBenficieryCert = async (item, index) => {
        // console.log("Generating Certificates")
        let arr = [...loaderArr];
        arr[index] = true;
        // console.log("Loader arr", loaderArr)
        setLoaderArr(arr)
        const result = await GenerateCert({
            trainingBeneficieryId: item._id
        })
        if (result) {
            downloadCert(result, item, index)
        }
    }

    const downloadCert = (Link, item, index) => {
        // console.log("pdf Link", Link)
        // console.log("Pdf person", item)
        fetch(Link).then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = item?.name + "-" + item?.contactNo + "-" + moment(new Date()).format("DD-MMM-YYYY") + ".pdf";
                alink.click();
                let arr = [...loaderArr];
                arr[index] = false;
                setLoaderArr(arr)
            });
        });

    }

    // ----sent sms code 

    const sendSmsToBenificialUser = async (id, index) => {
        setLoading(index);
        const result = await SendSms(id)

        if (result.status === 200) {
            setLoading(null);

        }

    }

    return (
        <div className={classes.main}>
            <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
                <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                    <IconButton style={{ margin: 10, padding: 0 }}>
                        <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#3A3A56' }} sx={{
                        }} >Trainer Data</Typography>
                    </IconButton>
                </div>
                <div>
                    <Table>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>District Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{data?.districtName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Training Location Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{data?.grampanchayatName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Training Module Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{data?.trainingModuleName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Trainer 1 Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{data?.trainerNameAll[0] ? data?.trainerNameAll[0] : '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Trainer 2 Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{data?.trainerNameAll[1] ? data?.trainerNameAll[1] : '-'}</TableCell>
                        </TableRow>
                        {data?.trainerMobileAll.length > 0 ? data?.trainerMobileAll.map((row, index) => {
                            return (<TableRow>
                                <TableCell style={{ fontSize: 20 }}>Trainier {index + 1} Mobile:</TableCell>
                                <TableCell style={{ fontSize: 20 }}>{row !== null ? row : '-'}</TableCell>
                            </TableRow>)
                        }) : null}
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Assignment Date:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{moment(data?.startDate).format("DD MMM YYYY")}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Training Status:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{data?.isApprovedFinal ? <span style={{ color: 'green' }} >Approved</span> : <span style={{ color: 'red' }}>Not Approved</span>}</TableCell>
                        </TableRow>
                    </Table>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Beneficiery List:</Typography>
                        {/* <Button variant="contained" style={{ margin: 25 }} disabled={beneficieryDetails !== undefined && beneficieryDetails.length === 0 || data?.isApprovedFinal === false} >Generate Certificate for All</Button> */}
                    </div>
                    <div style={{ margin: 5, fontSize: 18, fontWeight: 600 }}>
                        <span>Beneficiery count - {beneficieryDetails !== undefined && beneficieryDetails.length > 0 ? beneficieryDetails.length : 0} </span>
                    </div>
                    {loader ? <CircularProgress color="primary" /> :
                        beneficieryDetails !== undefined && beneficieryDetails.length > 0 ?
                            <Paper sx={{ overflow: 'hidden' }} >
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center' }}>SR No</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>Name</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>Gender</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>Contact No</TableCell>
                                                {/* <TableCell style={{ textAlign: 'center' }}>Action</TableCell> */}
                                                {/* <TableCell style={{ textAlign: 'center' }}>Sms</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                beneficieryDetails !== undefined && beneficieryDetails.length > 0 && beneficieryDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell style={{ textAlign: 'center' }}>{page * rowsPerPage + (index + 1)}</TableCell>
                                                            <TableCell style={{ textAlign: 'center' }}>{row?.name}</TableCell>
                                                            <TableCell style={{ textAlign: 'center' }}>{row?.gender}</TableCell>
                                                            <TableCell style={{ textAlign: 'center' }}>{row?.contactNo}</TableCell>
                                                            {/* <TableCell style={{ textAlign: 'center' }} >
                                                                {loaderArr[index] ? <CircularProgress color="primary" /> :
                                                                    <Button variant="contained" style={{ margin: 5 }} onClick={() => getBenficieryCert(row, index)} >
                                                                        Generate Certificate
                                                                    </Button>
                                                                }
                                                            </TableCell> */}
                                                            <TableCell style={{ textAlign: 'center' }} >

                                                                {/* {
                                                                    <span onClick={() => sendSmsToBenificialUser(row._id, index)}>
                                                                        {loading === index ? (
                                                                            <CircularProgress color="primary" />
                                                                        ) : (
                                                                            <Button variant="contained" style={{ margin: 5 }} >
                                                                                Send Sms
                                                                            </Button>
                                                                        )}
                                                                    </span>
                                                                } */}

                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={beneficieryDetails.length > 0 ? beneficieryDetails.length : 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper> : <span>No data found</span>
                    }

                </div>
                <center>

                </center>
            </Card>
            <Button variant="contained" onClick={() => navigate(-1)} >Back</Button>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '60%',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '500px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchBeneficieryList: (data) => dispatch(Action.fetchBeneficieryList(data)),
        GenerateCert: (data) => dispatch(Action.GenerateCert(data)),
        SendSms: (data) => dispatch(Action.SendSms(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBeneficieryList)
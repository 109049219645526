import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Card } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import * as Action from "../../actions/adminAction"
import Image1 from "../../Assets/Image/satara_Mandave.JPG"
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { BASE_URL } from "../../utils/urls";
require('../../App.css')


export const RolloutDetails = ({ userDetails }) => {

    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const Trainer = location.state
    const [gramData, setGramData] = useState();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getGramData()

    }, [])
    

    const getGramData = () => {
        setLoader(true);
        const url = BASE_URL + "/location/gettrainingLocationsinglelist";
        let header = new Headers();
        header.append("Content-Type", "application/x-www-form-urlencoded")
        let urlencoded = new URLSearchParams();
        urlencoded.append("School_id", Trainer?.grampanchayat_id);
        const opt = {
            method: 'POST',
            headers: header,
            body: urlencoded,
        }
        fetch(url, opt)
            .then(res => {
                console.log("res", res);
                if (res.status === 200) {
                    return res.json();
                }
                // else {
                //     alert("Error While Getting School Data");
                // }
            })
            .then(data => {
                console.log("GramData,", data)
                data !== undefined && data !== null && data !== "No data found" && setGramData(data[0])
                setLoader(false);
            })
            .catch(err => { alert("Error catched While getting Gram Data"); setLoader(false); })
    }

    const [openModal, setOpenModal] = useState(false);

    // console.log("Data", Trainer)
    // console.log("Details", userDetails)

    if (loader)
        return (<div className={classes.main}>
            <CircularProgress color="primary" />
        </div>)

    return (
        <div className={classes.main}>
            <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
                <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                    <IconButton style={{ margin: 10, padding: 0 }}>
                        <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#3A3A56' }} sx={{
                        }} >Plan Details</Typography>
                    </IconButton>
                </div>
                <div>
                    <Table>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Assignment Date:</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null ? dayjs(Trainer.startDate,).format("DD MMM YYYY") : '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Training Location:</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{gramData !== undefined && gramData !== null ? gramData.locationName : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Module:</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null ? Trainer.trainingModuleName : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Shift:</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null ? Trainer?.shift? Trainer?.shift?.toUpperCase():"-" : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Support Trainer Name:</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null ? Trainer.trainerNameAll.filter(item => item.toLowerCase() !== (userDetails.firstName.toLowerCase() + " " + userDetails.lastName.toLowerCase())).length > 0 ? Trainer.trainerNameAll.map((item, index) => {
                                if (item.toLowerCase() !== (userDetails.firstName.toLowerCase() + " " + userDetails.lastName.toLowerCase())) {
                                    return item+" ("+Trainer.trainerMobileAll[index]+")"
                                }
                            }) : "-" : "-"}</TableCell>
                        </TableRow>
                        {/* <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Trainer 1</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerNameAll.length>0 ? Trainer.trainerNameAll[0] : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Trainer 1 Mobile</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerMobileAll.length>0 ? Trainer.trainerMobileAll[0] : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Trainer 2</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerNameAll.length>1 ? Trainer.trainerNameAll[1] : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Trainer 2 Mobile</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerMobileAll.length>1 ? Trainer.trainerMobileAll[1] : "-"}</TableCell>
                        </TableRow> */}
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>District:</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{Trainer !== undefined && Trainer !== null ? Trainer.districtName : "-"}</TableCell>
                            {/* <TableCell style={{ fontSize: 16 }}>J.K. &#40;8893489833&#41;</TableCell> */}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>District Co-Ordinator:</TableCell>
                            <TableCell style={{ fontSize: 16 }}>{ Trainer?.DC?.firstName && Trainer?.DC?.lastName ? Trainer?.DC?.firstName + " " + Trainer?.DC?.lastName : '-' }</TableCell>
                            {/* <TableCell style={{ fontSize: 16 }}>J.K. &#40;8893489833&#41;</TableCell> */}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Sarpanch Name:</TableCell>
                            {/* <TableCell style={{ fontSize: 16 }}>{gramData !== undefined && gramData.length > 0 && gramData[0]?.sarpanchName !== null && gramData[0]?.sarpanchName !== 'null' ? gramData[0]?.sarpanchName : "-"}</TableCell> */}
                            <TableCell style={{ fontSize: 16 }}>{ Trainer?.Grampanchayat?.sarpanchName ? Trainer?.Grampanchayat?.sarpanchName : '-' }</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 16 }}>Gram Sevak Name:</TableCell>
                            {/* <TableCell style={{ fontSize: 16 }}>{gramData !== undefined && gramData.length > 0 && gramData[0]?.gramsevakhName !== null && gramData[0]?.gramsevakhName !== 'null' ? gramData[0]?.gramsevakhName : "-"}</TableCell> */}
                            <TableCell style={{ fontSize: 16 }}>{ Trainer?.Grampanchayat?.gramsevakhName ? Trainer?.Grampanchayat?.gramsevakhName : '-' }</TableCell>
                        </TableRow>
                    </Table>
                </div>
                <center>
                    <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
                </center>
            </Card>
        </div>
    )
}


const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30,
       },
    card: {
        width: '100%',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '1000px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
    image: {
        width: 150,
        height: 150
    },
    imageApprove: {
        backgroundColor: 'green',
        color: 'white'
    },
    imageReject: {
        backgroundColor: 'red',
        color: 'white'
    }
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RolloutDetails)
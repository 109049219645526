import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

export const AssignedTrainerList = ({ userDetails }) => {
  // console.log("UserDetails::::", userDetails);
  const classes = useStyles();
  const navigate = useNavigate();
  //data variables
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [trainerData, setTrainerData] = useState([]);
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //Error Variables
  const [cityError, setcityError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateDate = () => {
    let IsformValid = true;

    if (!city) {
      IsformValid = false;
      setcityError(true);
    }
    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }
    if (!panchayat) {
      IsformValid = false;
      setPanchayatError(true);
    }

    return IsformValid;
  };

  //GET Trianer data
  const getTrainerData = () => {
    setcityError(false);
    setTalukaError(false);
    setPanchayatError(false);

    if (validateDate()) {
      // console.log("Setting Trainer data");
      // setTrainerData(TrainerData)
    }
  };
  useEffect(() => {
    AlldistrictData();
  }, []);

  const AlldistrictData = async () => {
    setLoading(true);
    // if (formvalidate()) {
    //     // console.log("Form is Valid")

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let urlencoded = new URLSearchParams();
    urlencoded.append("user_id", userDetails._id);
    let data = {
      user_id: userDetails._id,
    };

    let requestoptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    await fetch(
      process.env.REACT_APP_BASE_URL+"/qualitycheckerdistrict/getuserdistrict",
      requestoptions
    )
      .then((response) => response.json())
      .then((res) => {
      
        setLoading(false);
        // console.log("-------View response assign district----------", res);
        setData(res);
        return res;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });

    //   }
    //   else {
    //     // console.log("Form is Invalid")
    //   }
  };

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          View Assigned District
        </Typography>
      </center>

      <Paper sx={{ overflow: "hidden", width: "90%" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>SR No.</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>District</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Status</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <div className="spinner-border text-primary" role="status" />
            ) : (
              <TableBody>
                {  Data !== "No data found" && Data.length > 0 ? (
                  Data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.districtName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          Assigned
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate(
                                "/QualityChecker/view-grampanchyat-trainerlist",
                                { state: row }
                              )
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={Data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
    width:'100%'
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedTrainerList);

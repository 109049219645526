import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Grid,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import { BASE_URL } from "../../utils/urls";
import moment from "moment";

export const ConsolidatedDistrictList = ({
  getAllDistrictData,
  getDistrictWiseTalukaData,
  getTalukaWisePanchayat,
  getAllTrainingModuleData,
  FetchApprovedTrainingData,
  FetchAllApprovedTrainingData,
  fetchDateWiseApprovedTrainingData,
  getAllTrainers,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  //data variables
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = useState("");
  const [panchayat, setPanchayat] = useState("");
  const [TalukaData, setTalukaData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [PanchayatData, setPanchayatData] = useState([]);

  //Error Variables
  const [cityError, setcityError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getDistrict();
  }, []);

  useEffect(() => {
    if (city) {
      getData();
      setTaluka("")
      setPanchayat("")
      getTaluka();
    }
  }, [city]);

  useEffect(() => {
    if (taluka) {
      getData();
      setPanchayat("")
      getPanchayat();
    }
  }, [taluka]);

  useEffect(() => {
    if (panchayat) {
      getData();
    }
  }, [panchayat]);

  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setcityData(result);
    }
  };

  const getTaluka = async () => {
    setTalukaError(false);
    const result = await getDistrictWiseTalukaData({
      distric_id: city?._id,
    });
    if (result !== "No data found") {
      setTalukaData(result);
      // console.log("Taluka list", result)
    } else {
      setTalukaError(true);
    }
  };

  const getPanchayat = async () => {
    setPanchayatError(false);
    const result = await getTalukaWisePanchayat({
      distric_id: city?._id,
      taluka_id: taluka?._id,
    });
    if (result !== "No data found") {
      setPanchayatData(result);
    } else {
      setPanchayatError(true);
    }
  };

  const getData = async () => {
    setLoader(true);

    let payload = {
      distric_id: city ? city._id : "",
      taluka_id: taluka ? taluka._id : "",
      grampanchayatName: panchayat ? panchayat.locationName : "",
    };

    await fetch(
      BASE_URL + "/trainingassigntrainer/grampanchyatListDistrictWise",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response !== "No data found") {
          setData(response[0]);
          setLoader(false);
        } else {
          setData([]);
          setLoader(false);
        }
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  const resetData = () => {
    setCity("")
    setTaluka("")
    setPanchayat("")
    setData([])
  }

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          Consolidated District wise Report
        </Typography>
      </center>
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
            flexWrap: "wrap",
          }}
        >
          <FormControl sx={{ m: 1, marginRight: 1 }}>
            <InputLabel id="outlined-adornment-Bank">District</InputLabel>
            <Select
              style={{ width: 200 }}
              id="outlined-adornment-Bank"
              label="District"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              disabled={cityData === undefined || cityData.length === 0}
            >
              {cityData &&
                cityData.map((item) => {
                  return (
                    <MenuItem key={item?._id} value={item}>
                      {item?.districtName}
                    </MenuItem>
                  );
                })}
            </Select>
            {cityError ? (
              <span style={{ color: "red", fontSize: 15 }}>
                *Please Select District
              </span>
            ) : null}
          </FormControl>
          <FormControl sx={{ m: 1, marginRight: 1 }}>
            <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
            <Select
              style={{ width: 200 }}
              id="outlined-adornment-Bank"
              label="Taluka"
              value={taluka}
              disabled={TalukaData.length === 0}
              onChange={(e) => {
                setTaluka(e.target.value);
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {TalukaData &&
                TalukaData.map((item) => {
                  return (
                    <MenuItem key={item?._id} value={item}>
                      {item?.talukaName}
                    </MenuItem>
                  );
                })}
            </Select>
            {TalukaError ? (
              <span style={{ color: "red", fontSize: 15 }}>
                *Please Select Taluka
              </span>
            ) : null}
          </FormControl>
          <FormControl sx={{ m: 1, marginRight: 1 }}>
            <InputLabel id="outlined-adornment-Bank">Training Location</InputLabel>
            <Select
              style={{ width: 200 }}
              id="outlined-adornment-month"
              label="Training Location"
              value={panchayat}
              disabled={PanchayatData.length === 0}
              onChange={(e) => {
                setPanchayat(e.target.value);
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {PanchayatData &&
                PanchayatData.map((item) => {
                  return (
                    <MenuItem key={item?._id} value={item}>
                      {item?.locationName}
                    </MenuItem>
                  );
                })}
            </Select>
            {PanchayatError ? (
              <span style={{ color: "red", fontSize: 15 }}>
                *Please Select Training Location
              </span>
            ) : null}
          </FormControl>
          <Button
          sx={{ m: 1, marginRight: 3 }}
          variant="contained"
          style={{ fontSize: 14 }}
          onClick={() => resetData()}
        >
          Reset Data
        </Button>
        </div>
      </Box>
      { !city ? <center>
        <Typography style={{ margin: 20, fontSize: 20, fontWeight: 600 }}>
          Please Select at least District first to get Data.
        </Typography>
      </center> :  loader ? (
        <CircularProgress color="primary" />
      ) : (
        <Paper sx={{ overflow: "hidden", width: "90%" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>SR No.</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Trainer Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Support Trainer</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>District</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Taluka</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>GramPanchyat</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Training Module</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Training Start Date</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Documents Uploaded</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { data !== "No data found" &&
                data.grampanchayats &&
                data.grampanchayats.length > 0 ? (
                  data.grampanchayats.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1 + (rowsPerPage * page) }
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerNameAll[0]
                            ? row?.trainerNameAll[0]
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerNameAll[1]
                            ? row?.trainerNameAll[1]
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.districtName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.talukaName ? row?.talukaName : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.grampanchayatName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainingModuleName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {moment(row?.startDate).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.docUploadedCount}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <Typography variant="h6" component="div" textAlign="center">
                  <IconButton>
                    <BarChartIcon />
                  </IconButton>
                  Total - <strong>{trainerData?.count}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                justifyContent="flex-end"
              >
                <PaginationComponent
                  currentPage={page}
                  totalPages={noOfPages}
                  onPageChange={handleChangePage}
                  onDocsPerPageChange={handleChangeRowsPerPage}
                  docsPerPage={rowsPerPage}
                />
              </Grid>
            </Grid>
          </Box> */}
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            // count={count ? count : 0}
            count={
              data !== "No data found" && data.grampanchayats ? data?.grampanchayats.length : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
  image: {
    width: 80,
    height: 80,
  },
  imageApprove: {
    width: 80,
    height: 80,
    border: "5px solid green",
  },
  imageReject: {
    width: 80,
    height: 80,
    border: "5px solid red",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    img1: state.admin.img1,
    img2: state.admin.img2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getDistrictWiseTalukaData: (data) =>
      dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) =>
      dispatch(Action.getTalukaWisePanchayat(data)),
    getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
    FetchApprovedTrainingData: (data) =>
      dispatch(Action.FetchApprovedTrainingData(data)),
    FetchAllApprovedTrainingData: () =>
      dispatch(Action.FetchAllApprovedTrainingData()),
    fetchDateWiseApprovedTrainingData: (data) =>
      dispatch(Action.fetchDateWiseApprovedTrainingData(data)),
    getAllTrainers: () => dispatch(Action.getAllTrainers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidatedDistrictList);

import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, InputLabel, TableHead, TableBody, TableRow, TableCell, Typography, Button, Paper, Select, Modal, IconButton, TextField, FormControl, Card, CircularProgress, MenuItem } from "@mui/material";
import * as Action from "../../actions/adminAction"
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Videos from "../../Assets/Image/Video.png"
import DOC from "../../Assets/Image/doc.png"
import VerifiedIcon from '@mui/icons-material/Verified';
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import nothere from '../../Assets/Image/nope-not-here.webp';
import moment from "moment";
require('../../App.css')

export const ReportForm = ({ userDetails, getCategories, GenerRateReport, getSingleTraining }) => {

    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const trainer = location.state

    const getTrainingData = async () => {
        setLoader(true)
        const result = await getSingleTraining({
            trainingAssignTrainer_id: trainer?._id
        })
        if (result !== "No data found" && result !== undefined) {
            // console.log("Training", result[0])
            setTraining(result[0])
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    //Approved Images
    const [categories, setcategories] = useState([])
    const [loader, setLoader] = useState(false);
    const [reportLoader, setReportLoader] = useState(false)
    const [imageSelected, setImageSelected] = useState([])

    //Report Form

    const [training, setTraining] = useState('')
    const [trainingPlace, setTrainingPlace] = useState('')
    const [TotalBeneCount, setTotalBeneCount] = useState('0')
    const [maleBeneCount, setmaleBeneCount] = useState('0')
    const [femaleBeneCount, setfemaleBeneCount] = useState('0')
    const [scCount, setScCount] = useState('0')
    const [stCount, setStCount] = useState('0')
    const [obcCount, setObcCount] = useState('0')
    const [openCount, setOpenCount] = useState('0')
    const [handicappedCount, setHandicappedCount] = useState('0')

    //Numerical reprsentation
    const [gramPanachayatMembers, setGramPanachayatMembers] = useState('0')
    const [waterProviders, setWaterProviders] = useState('0')
    const [healthCount, setHealthCount] = useState('0')
    const [agriCount, setAgriCount] = useState('0')
    const [fpoCount, setFPOCount] = useState('0')
    const [femaleGroupCount, setFemaleGroupCount] = useState('0')
    const [otherCount, setOtherCount] = useState('0')
    const [total, setTotal] = useState('0')
    const [feedback, setFeedback] = useState([])

    //Feedback form
    // const [feedback, setFeedback] = useState([
    //     { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
    //     { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
    //     { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
    //     { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
    //     { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
    //     { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
    //     { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
    //     { details: 'एकूण प्रभाव', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
    // ])

    const [selectedDetails, SetselectedDetails] = useState('')
    const [selectedसर्वोत्तम, Setselectedसर्वोत्तम] = useState('0')
    const [selectedउत्तम, Setselectedउत्तम] = useState('0')
    const [selectedबरा, Setselectedबरा] = useState('0')
    const [selectedअनपेक्षित, Setselectedअनपेक्षित] = useState('0')
    const [selectedवाईट, Setselectedवाईट] = useState('0')
    const [error, setError] = useState(false)
    const [trainer1Sign, setTrainer1Sign] = useState('')
    const [trainer2Sign, setTrainer2Sign] = useState('')
    const [trainingDate, setTrainingDate] = useState('')


    const feedback_Dropdown = [
        'प्रशिक्षण आराखडा', 'प्रशिक्षण साहित्य', 'तांत्रिक परीक्षक', 'प्रात्यक्षिक सत्र', 'नाष्ट्याची गुणवत्ता', 'प्रशिक्षकांशी संवाद', 'प्रशिक्षणाची उपयोगिता', 'एकूण प्रभाव'
    ]

    const [result, setResult] = useState([
        {
            id: 1, विषय: 'अटल भूजल योजनेतून बनलवेला जलसरुक्षा आराखडा माहिती', निर्णय: 'जलसरुक्षा आराखड्याची माहती देण्यासाठी विशेष ग्रामसभा घेण्याचा निर्णय'
        },
        {
            id: 2, विषय: 'समूह संघटन', निर्णय: 'ग्राम पंचायत अंतर्गत पेयजल सुरक्षा समिती, कृषी समिती यांचा विस्तार करून सर्व प्रभागांतील स्वयसेवकांना स्थान देण्याचा निर्णय'
        },
        {
            id: 3, विषय: '', निर्णय: ''
        },
        {
            id: 4, विषय: '', निर्णय: ''
        },
    ])

    //error
    const [feedbackError, setFeedbackError] = useState(false);
    const [imageError, setImageError] = useState(false)
    const [placeError, setPlaceError] = useState(false)
    const [reportImageError, setReportImageError] = useState(false)
    const [maleBeneError, setMaleBeneError] = useState(false)
    const [femaleBeneError, setFemaleBeneError] = useState(false)
    const [scCountError, setScCountError] = useState(false)
    const [stCountError, setStCountError] = useState(false)
    const [obcCountError, setObcCountError] = useState(false)
    const [handicappedCountError, setHandicappedCountError] = useState(false)
    const [gramPanachayatMembersError, setGramPanachayatMembersError] = useState(false)
    const [waterProvidersError, setWaterProvidersError] = useState(false)
    const [healthCountError, setHealthCountError] = useState(false)
    const [agriCountError, setAgriCountError] = useState(false)
    const [fpoCountError, setFPOCountError] = useState(false)
    const [femaleGroupCountError, setFemaleGroupCountError] = useState(false)
    const [otherCountError, setOtherCountError] = useState(false)
    const [totalError, setTotalError] = useState(false)
    const [openCountError, setOpenCountError] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [Sign1Error, setSign1Error] = useState(false)
    const [Sign2Error, setSign2Error] = useState(false)
    const [allcatArraay,setAllCatArray] = useState([])

    const getAllCategories = async () => {
        setLoader(true)
        const result = await getCategories({
            "grampanchayat_id": trainer?.grampanchayat_id,
            "trainingModule_id": trainer?.trainingModule_id
        })
        if (result) {
            let arr = result
            let new_arr = arr.filter(item => item._id !== `Participant's Video Testimonial` &&
                item._id !== 'Government official visit record' &&
                item._id !== 'Training Attendance Sheet' && item._id !== 'Training Outcome Report'
            )
            setcategories(new_arr)
            setAllCatArray(result)
            setLoader(false)
        }
    }

    useEffect(() => {
        getAllCategories()
        getTrainingData()
    }, [])

    useEffect(() => {
        if (training) {
            // console.log(training?.trainingPlace)
            if (training?.castBifircation.length > 0 && training?.feedbackformData.length > 0 &&
                training?.representative.length > 0 && training?.resultform.length > 0
                && training?.totalBeneficiaryData.length > 0) {
                // console.log("Setting values")
                setFeedback(training?.feedbackformData)
                setResult(training?.resultform)
                setTrainingPlace(training?.trainingPlace)
                setTrainingDate(training?.trainingDate)
                setTotalBeneCount(training?.totalBeneficiaryData[0].एकूण)
                setmaleBeneCount(training?.totalBeneficiaryData[0].परुुष)
                setfemaleBeneCount(training?.totalBeneficiaryData[0].महिला)
                setScCount(training?.castBifircation[0].अजा)
                setStCount(training?.castBifircation[0].अज)
                setObcCount(training?.castBifircation[0].इतरमागास)
                setOpenCount(training?.castBifircation[0].खुला)
                setHandicappedCount(training?.castBifircation[0].दिव्यांग)
                setGramPanachayatMembers(training?.representative[0].ग्रामपंचायतसदस्य)
                setWaterProviders(training?.representative[0].पाणीपुरवठाआणिस्वच्छतासमिती)
                setHealthCount(training?.representative[0].आरोग्यसमिती)
                setAgriCount(training?.representative[0].कृषीसमिती)
                setFPOCount(training?.representative[0].एफपीओसामुदायिकआधारितसंस्था)
                setFemaleGroupCount(training?.representative[0].महिलागट)
                setOtherCount(training?.representative[0].इतरसंस्था)
                setTotal(training?.representative[0].एकूण)
                if (training?.trainerSignature) {
                    setTrainer1Sign(training?.trainerSignature[0])
                    setTrainer2Sign(training?.trainerSignature[1])
                }
            } else {
                // console.log("No data added by trainer")
            }

            
            if(trainer?.trainingModuleName === "4 Water Budget and crop Planning and interchange"){
            setFeedback([
            { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '26', उत्तम: '24', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
            { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '23', उत्तम: '27', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
            { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '28', उत्तम: '22', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
            { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '25', उत्तम: '25', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
            { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '22', उत्तम: '28', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
            { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '27', उत्तम: '13', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
            { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '29', उत्तम: '21', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
            { details: 'एकूण प्रभाव', सर्वोत्तम: '26', उत्तम: '24', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
        ])
            }else if(trainer?.trainingModuleName === "5 Water Saving Technologies in Agriculture"){
                setFeedback([
                { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '22', उत्तम: '18', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
                { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '24', उत्तम: '16', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
                { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '27', उत्तम: '13', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
                { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '25', उत्तम: '15', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
                { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '29', उत्तम: '11', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
                { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '24', उत्तम: '16', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
                { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '22', उत्तम: '18', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
                { details: 'एकूण प्रभाव', सर्वोत्तम: '23', उत्तम: '17', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
            ])
            }else{
                setFeedback([
                { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '14', उत्तम: '11', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
                { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '18', उत्तम: '7', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
                { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '13', उत्तम: '12', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
                { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '16', उत्तम: '9', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
                { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '14', उत्तम: '11', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
                { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '19', उत्तम: '6', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
                { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '15', उत्तम: '10', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
                { details: 'एकूण प्रभाव', सर्वोत्तम: '13', उत्तम: '12', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
            ])
            }


        }
    }, [training])




    useEffect(() => {
        if (maleBeneCount !== '' && femaleBeneCount !== '') {
            setTotalBeneCount(parseInt(maleBeneCount) + parseInt(femaleBeneCount))
        }
    }, [maleBeneCount, femaleBeneCount])

    const formvalidate = () => {
        let IsformValid = true;

        if (!trainingPlace) {
            IsformValid = false;
            setPlaceError(true)
        }

        if (!maleBeneCount) {
            IsformValid = false;
            setMaleBeneError(true)
        }

        if (!femaleBeneCount) {
            IsformValid = false;
            setFemaleBeneError(true)
        }

        if (!scCount) {
            IsformValid = false;
            setScCountError(true)
        }

        if (!stCount) {
            IsformValid = false;
            setStCountError(true)
        }

        if (!obcCount) {
            IsformValid = false;
            setObcCountError(true)
        }

        if (!handicappedCount) {
            IsformValid = false;
            setHandicappedCountError(true)
        }

        if (!gramPanachayatMembers) {
            IsformValid = false;
            setGramPanachayatMembersError(true)
        }

        if (!waterProviders) {
            IsformValid = false;
            setWaterProvidersError(true)
        }

        if (!healthCount) {
            IsformValid = false;
            setHealthCountError(true)
        }

        if (!agriCount) {
            IsformValid = false;
            setAgriCountError(true)
        }

        if (!fpoCount) {
            IsformValid = false;
            setFPOCountError(true)
        }

        if (!femaleGroupCount) {
            IsformValid = false;
            setFemaleGroupCountError(true)
        }

        if (!otherCount) {
            IsformValid = false;
            setOtherCountError(true)
        }

        if (!total) {
            IsformValid = false;
            setTotalError(true)
        }

        // console.log("Validating Image Array", imageSelected)
        if (imageSelected.length < 4) {
            // console.log("Image Error")
            IsformValid = false;
            setReportImageError(true)
        }

        if (!openCount) {
            IsformValid = false;
            setOpenCountError(true)
        }

        if (!trainingDate) {
            // console.log(trainingDate)
            IsformValid = false;
            setDateError(true)
        }

        if (!trainer1Sign) {
            IsformValid = false;
            setSign1Error(true)
        }

        if ( trainer?.trainerNameAll.length === 2 && !trainer2Sign) {
            IsformValid = false;
            setSign2Error(true)
        }

        return IsformValid;
    }

    const hadleFormSubmit = async () => {
        setReportLoader(true)
        setMaleBeneError(false)
        setFemaleBeneError(false)
        setPlaceError(false)
        setScCountError(false)
        setStCountError(false)
        setObcCountError(false)
        setHandicappedCountError(false)
        setGramPanachayatMembersError(false)
        setWaterProvidersError(false)
        setHealthCountError(false)
        setAgriCountError(false)
        setFPOCountError(false)
        setFemaleGroupCountError(false)
        setOtherCountError(false)
        setTotalError(false)
        setReportImageError(false)
        setOpenCountError(false)


        if (formvalidate()) {
            // console.log("beneficiery count", TotalBeneCount, maleBeneCount, femaleBeneCount)
            // console.log("Class bifurcation", scCount, stCount, obcCount, openCount, handicappedCount)
            // console.log("Numerical", gramPanachayatMembers, waterProviders, healthCount, agriCount, fpoCount, femaleGroupCount, otherCount, total)
            // console.log("report Images", imageSelected)
            // console.log("Feedback Count", feedback)
            // console.log("Result data", result)


            let feedbackarray =  allcatArraay.filter(item => item._id === `Feedback Form`)[0].category
            // console.log("Feedback Array",feedbackarray)
     
            // imageSelected.map( (row) => {
            //     if(feedbackarray.filter( item => item?._id === row?._id ).length > 0){
            //         valid = true;
            //     }
            // } )

            if(feedbackarray.length>0){
                // console.log("Feedback Image Found");
                let BeneficieryData = {
                    "एकूण": TotalBeneCount,
                    "परुुष": maleBeneCount,
                    "महिला": femaleBeneCount
                }
    
                let castbifurcation = {
                    "अजा": scCount,
                    "अज": stCount,
                    "इतरमागास": obcCount,
                    "खुला": openCount,
                    "दिव्यांग": handicappedCount,
                }
    
                let representative = {
                    "ग्रामपंचायतसदस्य": gramPanachayatMembers,
                    "पाणीपुरवठाआणिस्वच्छतासमिती": waterProviders,
                    "आरोग्यसमिती": healthCount,
                    "कृषीसमिती": agriCount,
                    "एफपीओसामुदायिकआधारितसंस्था": fpoCount,
                    "महिलागट": femaleGroupCount,
                    "इतरसंस्था": otherCount,
                    "एकूण": total,
                }
                let imageArray = []
                imageSelected.map((row) => {
                    imageArray.push(row?.documentUrl)
                })

                let data = {
                    ...trainer,
                    trainingPlace: trainingPlace,
                    // trainingDate: trainingDate,
                    trainingDate:trainingDate,
                    trainerSignature: [trainer1Sign, trainer2Sign],
                    BeneficieryData: BeneficieryData,
                    castbifurcation: castbifurcation,
                    representative: representative,
                    reportImages: imageArray,
                    feedbackform: feedback,
                    resultform: result
                }
    
                // console.log("Payload data", data)
                const res = await GenerRateReport(data)

                console.log(res)

                if (res) {
                    if(res.message != "No report data found"){
                    downloadReport(res, trainer)

                    }else{
                        alert(res.message)
                    setReportLoader(false)

                    }
                } else {
                    // console.log("Error")
                    setReportLoader(false)
                }
            }else{
                // console.log("Sorry, Report cannot be created due to no feedback form images available");
                setFeedbackError(true);
                setReportLoader(false);
                
            }
        } else {
            setReportLoader(false)
        }
    }


    const downloadReport = (Link, item) => {
        fetch(Link).then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = item?.districtName + "-" + item?.grampanchayatName + "-" + item?.trainingModuleName + "-" + "ReportPDF.pdf";
                alink.click();
                setTimeout(() => {
                    setReportLoader(false)
                    navigate("/admin/Create-training-report", { state: trainer })
                }, [2000])
            });
        });

    }

    const handlePushImage = async (item) => {
        setImageError(false)
        if (imageSelected.length < 6) {
            setImageSelected([...imageSelected, item]); // new reference is given and updating the reference
        } else {
            // console.log("Cannot add more than 6")
            setImageError(true)
        }
    }

    const handlePopImage = (item) => {
        // console.log("Delete img", item)
        let arr = [...imageSelected]
        let val = arr.findIndex(row => row?._id === item?._id)
        arr.splice(val, 1)
        setImageSelected(arr)
        // console.log("After Deleteing array", arr)
    }

    const validate = () => {
        let IsformValid = true;

        if (!selectedDetails) {
            IsformValid = false;
        }

        if (!selectedसर्वोत्तम) {
            IsformValid = false;
        }

        if (!selectedउत्तम) {
            IsformValid = false
        }

        if (!selectedबरा) {
            IsformValid = false
        }

        if (!selectedअनपेक्षित) {
            IsformValid = false
        }

        if (!selectedवाईट) {
            IsformValid = false
        }

        // console.log(IsformValid)
        return IsformValid;
    }

    const updateFeedback = () => {
        if (validate()) {
            let i = feedback.findIndex(row => row?.details === selectedDetails)
            let arr = [...feedback]
            arr[i] = {
                ...arr[i],
                सर्वोत्तम: selectedसर्वोत्तम,
                उत्तम: selectedउत्तम,
                बरा: selectedबरा,
                अनपेक्षित: selectedअनपेक्षित,
                वाईट: selectedवाईट
            }
            setFeedback(arr)
            // console.log("updated form", arr)
            SetselectedDetails('')
            Setselectedसर्वोत्तम('0')
            Setselectedउत्तम('0')
            Setselectedबरा('0')
            Setselectedअनपेक्षित('0')
            Setselectedवाईट('0')

        } else {
            // console.log("Form Invalid")
            setError(true)
        }
    }

    const handleResult = (Id, name, value) => {
        let arr = [...result]
        let i = arr.findIndex(row => row?.id === Id)
        arr[i] = {
            ...arr[i],
            [name]: value
        }
        // console.log("Changed data", arr)
        setResult(arr)
    }

    const uploadImageToS3 = (event, type) => {
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            dirName: process.env.REACT_APP_DIR_NAME + "/",
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: "https://gsda-images2.s3.ap-south-1.amazonaws.com",
        };

        let fileInput = false;
        const image = event;
        // console.log("image data before compression-------------- ", image);
        if (event) {
            fileInput = true;
        }
        if (fileInput) {
            new Compressor(image, {
                quality: 0.9,
                success: (compressedResult) => {
                    let getTimeStamp = new Date().getTime();
                    let newFileName = getTimeStamp + "_";
                    // console.log("------- filename image upload --------", newFileName);

                    const ReactS3Client = new S3(config);

                    ReactS3Client.uploadFile(compressedResult, newFileName)
                        .then((res) => {
                            if (res.status === 204) {
                                if (type === "Sign1") {
                                    setTrainer1Sign(res.location);
                                } else if (type === "Sign2") {
                                    setTrainer2Sign(res.location);
                                }
                            } else {
                                // console.log("AWS Error")
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                },
            });
            // console.log("--------------- Upload image -----------");
        }
    };

    const handleChange = async (e, ImgType) => {
        if (e.target.files.length) {
            const img = URL.createObjectURL(e.target.files[0]);
            // console.log("Files Type", e.target.files[0]);
            if (e.target.files[0].type.split('/')[0] === 'image') {
                uploadImageToS3(e.target.files[0], ImgType);
                // console.log("Its valid Image")
            } else {
                // console.log("Something Else")
            }
        }
    };
    
    return (
        <div className={classes.main}>
            <Card className={classes.profilemodal} style={{ backgroundColor: 'whitesmoke', flexWrap: 'wrap' }}>
                <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                    <IconButton style={{ margin: 10, padding: 0 }}>
                        <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#3A3A56' }} sx={{
                        }} >Required data to genrate report</Typography>
                    </IconButton>
                </div>
                <div>
                    <Table>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>District </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.districtName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>Taluka </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.talukaName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>Training Location </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.grampanchayatName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>Module </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.trainingModuleName}</TableCell>
                        </TableRow>
                    </Table>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', flexWrap: 'wrap', marginTop: 30 }}>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Training Place</Typography>
                            </IconButton>
                        </div>
                        <center>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 350 }}
                                    id="outlined-adornment-TrainerName"
                                    label="प्रशिक्षण स्थळ"
                                    value={trainingPlace}
                                    onChange={(e) => setTrainingPlace(e.target.value)}
                                    error={placeError}
                                    helperText={
                                        placeError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Please Enter Training Place
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                        </center>
                    </div>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Training Date & Trainers Signature</Typography>
                            </IconButton>
                        </div>
                        <center>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Training Date"
                                        value={trainingDate}
                                        onChange={(newValue) => {
                                            // console.log("-------date selected--------",newValue.$d)
                                            let dteee = new Date( newValue.$d )
                                            dteee.setMinutes(dteee.getMinutes() + dteee.getTimezoneOffset())
                                            // // console.log(dteee)
                                            setTrainingDate(dteee);
                                        }}
                                        inputFormat={"DD/MM/YYYY"}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                {dateError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Select Training Date</span> : null}
                            </FormControl>
                        </center>
                        <center >
                            <Table>
                                <TableRow>
                                    <TableCell width={180}>
                                        <h6>{trainer?.trainerNameAll ? trainer?.trainerNameAll[0] : '-'}</h6>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={{ m: 1 }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <a target="_blank" href={trainer1Sign ? trainer1Sign : null}><img src={trainer1Sign ? trainer1Sign : nothere} style={{ width: 140, height: 60, border: '1px solid black', marginLeft: 10, marginRight: 10 }} /></a>
                                                <input accept="image/*" type="file" onChange={(e) => handleChange(e, "Sign1")} />
                                            </div>
                                            {Sign1Error ? <span style={{ color: 'red', fontSize: 15 }}>*Please Upload Trainer 1 Sign</span> : null}
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </Table>
                            <Table>
                                <TableRow>
                                    <TableCell width={180}>
                                        <h6>{trainer?.trainerNameAll ? trainer?.trainerNameAll[1] : '-'}</h6>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={{ m: 1 }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <a target="_blank" href={trainer2Sign ? trainer2Sign : null} >
                                                    <img src={trainer2Sign ? trainer2Sign : nothere} style={{ width: 140, height: 60, border: '1px solid black', marginLeft: 10, marginRight: 10 }} />
                                                </a>
                                                <input accept="image/*" type="file" onChange={(e) => handleChange(e, "Sign2")} />
                                            </div>
                                            {Sign2Error ? <span style={{ color: 'red', fontSize: 15 }}>*Please Upload Trainer 2 Sign</span> : null}
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </center>
                    </div>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Beneficiery Data</Typography>
                            </IconButton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 20 }}>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="एकूण"
                                    value={TotalBeneCount}
                                    disabled={true}
                                    onChange={(e) => setTotalBeneCount(e.target.value)}
                                // helperText={
                                //   contactError ? (
                                //     <span style={{ color: "red", fontSize: 15 }}>
                                //       *Please Enter Conatct Number
                                //     </span>
                                //   ) : null
                                // }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="परुुष"
                                    value={maleBeneCount}
                                    onChange={(e) => setmaleBeneCount(e.target.value)}
                                    error={maleBeneError}
                                    helperText={
                                        maleBeneError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Please Enter Male Beneficiery Count
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="महिला"
                                    value={femaleBeneCount}
                                    onChange={(e) => setfemaleBeneCount(e.target.value)}
                                    error={femaleBeneError}
                                    helperText={
                                        femaleBeneError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Please Enter Female Beneficiery Count
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Cast bifurcation of beneficiary</Typography>
                            </IconButton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 20 }}>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="अ.जा."
                                    value={scCount}
                                    onChange={(e) => setScCount(e.target.value)}
                                    error={scCountError}
                                    helperText={
                                        scCountError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="अ.ज."
                                    value={stCount}
                                    onChange={(e) => setStCount(e.target.value)}
                                    error={stCountError}
                                    helperText={
                                        stCountError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="इतर मागास"
                                    value={obcCount}
                                    onChange={(e) => setObcCount(e.target.value)}
                                    error={obcCountError}
                                    helperText={
                                        obcCountError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 10, justifyContent: 'center' }}>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="खुला"
                                    value={openCount}
                                    onChange={(e) => setOpenCount(e.target.value)}
                                    error={openCountError}
                                    helperText={
                                        openCountError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="दिव्यांग"
                                    value={handicappedCount}
                                    onChange={(e) => setHandicappedCount(e.target.value)}
                                    error={handicappedCountError}
                                    helperText={
                                        handicappedCountError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Representative</Typography>
                            </IconButton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 20 }}>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="ग्रामपंचायत सदस्य"
                                    value={gramPanachayatMembers}
                                    onChange={(e) => setGramPanachayatMembers(e.target.value)}
                                    error={gramPanachayatMembersError}
                                    helperText={
                                        gramPanachayatMembersError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="पाणी पुरवठा आणि स्वच्छता समिती"
                                    value={waterProviders}
                                    onChange={(e) => setWaterProviders(e.target.value)}
                                    error={waterProvidersError}
                                    helperText={
                                        waterProvidersError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="आरोग्य समिती"
                                    value={healthCount}
                                    onChange={(e) => setHealthCount(e.target.value)}
                                    error={healthCountError}
                                    helperText={
                                        healthCountError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 10 }}>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="कृषी समिती"
                                    value={agriCount}
                                    onChange={(e) => setAgriCount(e.target.value)}
                                // helperText={
                                //   contactError ? (
                                //     <span style={{ color: "red", fontSize: 15 }}>
                                //       *Please Enter Conatct Number
                                //     </span>
                                //   ) : null
                                // }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="एफपीओ/सामुदायिक आधारित संस्था"
                                    value={fpoCount}
                                    onChange={(e) => setFPOCount(e.target.value)}
                                // helperText={
                                //   contactError ? (
                                //     <span style={{ color: "red", fontSize: 15 }}>
                                //       *Please Enter Conatct Number
                                //     </span>
                                //   ) : null
                                // }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="महिला गट"
                                    value={femaleGroupCount}
                                    onChange={(e) => setFemaleGroupCount(e.target.value)}
                                // helperText={
                                //   contactError ? (
                                //     <span style={{ color: "red", fontSize: 15 }}>
                                //       *Please Enter Conatct Number
                                //     </span>
                                //   ) : null
                                // }
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 10, justifyContent: 'center' }}>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="इतर संस्था"
                                    value={otherCount}
                                    onChange={(e) => setOtherCount(e.target.value)}
                                    error={otherCountError}
                                    helperText={
                                        otherCountError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, marginRight: 3 }}>
                                <TextField
                                    style={{ width: 200 }}
                                    id="outlined-adornment-TrainerName"
                                    label="एकूण"
                                    value={total}
                                    onChange={(e) => setTotal(e.target.value)}
                                    error={totalError}
                                    helperText={
                                        totalError ? (
                                            <span style={{ color: "red", fontSize: 15 }}>
                                                *Count Cannot be Empty.
                                            </span>
                                        ) : null
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Select Images for Report</Typography>
                            </IconButton>
                        </div>
                        {reportImageError ? <div style={{ margin: 20 }}>
                            <span style={{ color: "red", fontSize: 15 }}>*Select at least 4 Images.</span>
                        </div> : null}

                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
                            {loader ? <CircularProgress color="primary" /> : categories.map((row) => {
                                return (
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#3A3A56', margin: 10 }}>
                                            <Typography style={{ fontSize: 18, fontWeight: 700, marginLeft: 10, color: 'white' }}> {row?._id}</Typography>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
                                            {
                                                row?.category?.length > 0 ? row?.category.map((item) => {
                                                    if (item?.categoryDoc.split('/')[0] === 'image') {
                                                        if (item?.status === 'approve')
                                                            return (
                                                                <div className='imgReport'>
                                                                    {imageSelected.length > 0 && imageSelected.filter(row => row?._id === item?._id).length === 1 ?
                                                                        <div>
                                                                            <div className='statusIcon'>
                                                                                <IconButton size="large" aria-label="status" onClick={e => {
                                                                                    // console.log("Delete Clicked !!!!!")
                                                                                }}>
                                                                                    <VerifiedIcon style={{ fontSize: '40px' }} color="success" />
                                                                                    {/* <CancelIcon style={{fontSize:'32px'}} color="error" /> */}
                                                                                </IconButton>
                                                                            </div>
                                                                            <div >
                                                                                <button className='pushtoReportReject' onClick={() => handlePopImage(item)}>
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                        </div> :
                                                                        <div >
                                                                            <button className='pushtoReportApprove' onClick={() => handlePushImage(item)} >
                                                                                Approve
                                                                            </button>
                                                                        </div>
                                                                    }

                                                                    <div className='img'>
                                                                        <a target="_blank" href={item?.documentUrl}><img src={item?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                                                                    </div>
                                                                </div>
                                                            )
                                                    } else if (item?.categoryDoc.split('/')[0] === 'video') {
                                                        if (item?.status === 'approve')
                                                            return (
                                                                <div className='imgReport'>
                                                                    <div >
                                                                        <button className='pushtoReportApprove' >
                                                                            Approve
                                                                        </button>
                                                                    </div>
                                                                    <div >
                                                                        <button className='pushtoReportReject' >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                    <div className='img'>
                                                                        <a target="_blank" href={item?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                                                                    </div>
                                                                </div>
                                                            )
                                                    } else {
                                                        if (item?.status === 'approve')
                                                            return (
                                                                <div className='imgReport'>
                                                                    <div>
                                                                        <button className='pushtoReportApprove' >
                                                                            Approve
                                                                        </button>
                                                                    </div>
                                                                    <div >
                                                                        <button className='pushtoReportReject' >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                    <div className='img'>
                                                                        <a target="_blank" href={item?.documentUrl}><img src={DOC} style={{ height: '100%', width: '100%' }} /></a>
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                }) : <Typography>No data found</Typography>
                                            }
                                        </div>
                                        <br />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Feedback Form</Typography>
                            </IconButton>
                        </div>
                        <div>
                            <center>
                                <FormControl sx={{ m: 2, paddingRight: 4 }} >
                                    <InputLabel id="outlined-adornment-Bank">तपशील</InputLabel>
                                    <Select
                                        style={{ width: 200 }}
                                        id="outlined-adornment-Bank"
                                        label="तपशील"
                                        value={selectedDetails}
                                        onChange={(e) => { SetselectedDetails(e.target.value); }}
                                        MenuProps={{
                                            style: {
                                                maxHeight: 400,
                                            },
                                        }}
                                    >
                                        {feedback_Dropdown.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </center>
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 20 }}>
                                <FormControl sx={{ m: 1 }}>
                                    <TextField
                                        style={{ width: 120 }}
                                        // error={selectedसर्वोत्तम.match(/[A-Za-z+@#$&%!~]/)}
                                        id="outlined-adornment-TrainerName"
                                        label="सर्वोत्तम"
                                        value={selectedसर्वोत्तम}
                                        onChange={(e) => {
                                            if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                                Setselectedसर्वोत्तम(e.target.value)
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1 }}>
                                    <TextField
                                        style={{ width: 120 }}
                                        id="outlined-adornment-TrainerName"
                                        label="उत्तम"
                                        value={selectedउत्तम}
                                        onChange={(e) => {
                                            if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                                Setselectedउत्तम(e.target.value)
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1 }}>
                                    <TextField
                                        style={{ width: 120 }}
                                        id="outlined-adornment-TrainerName"
                                        label="बरा"
                                        value={selectedबरा}
                                        onChange={(e) => {
                                            if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                                Setselectedबरा(e.target.value)
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1 }}>
                                    <TextField
                                        style={{ width: 120 }}
                                        id="outlined-adornment-TrainerName"
                                        label="अनपेक्षित"
                                        value={selectedअनपेक्षित}
                                        onChange={(e) => {
                                            if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                                Setselectedअनपेक्षित(e.target.value)
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1 }}>
                                    <TextField
                                        style={{ width: 120 }}
                                        id="outlined-adornment-TrainerName"
                                        label="वाईट"
                                        value={selectedवाईट}
                                        onChange={(e) => {
                                            if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                                Setselectedवाईट(e.target.value)
                                            }
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <center>
                                <Button style={{ margin: 5 }} variant="contained" onClick={() => updateFeedback()}>Update</Button>
                            </center>
                        </div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 10, height: 1 }} />
                        <div style={{ width: '100%' }}>
                            <Table style={{ margin: 10, background: 'white', width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
                                <TableHead style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }} rowSpan={2}>
                                            क्र
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }} rowSpan={2}>
                                            तपशील
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }} colSpan={5}>
                                            अभिप्राय श्रेणीनाय प्रशिक्षणार्थी संख्या
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>सर्वोत्तम</TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}> उत्तम</TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>बरा</TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>अनपेक्षित</TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>वाईट</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {feedback.length > 0 && feedback.map((row) => {
                                        return (
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }} >
                                                    {row?.id}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                    {row?.details}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                    {row?.सर्वोत्तम}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                    {row?.उत्तम}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                    {row?.बरा}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                    {row?.अनपेक्षित}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                    {row?.वाईट}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 5, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#3A3A56' }} sx={{
                                }} >Training Result Form</Typography>
                            </IconButton>
                        </div>
                        <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 10, height: 1 }} />
                        <div style={{ width: '100%' }}>
                            <Table style={{ margin: 10, background: 'white', width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
                                <TableHead style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600, width: '10%' }} >
                                            क्र
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600, width: '30%' }} >
                                            विषय
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600, width: '60%' }} >
                                            निर्णय
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        result.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>{row?.id}</TableCell>
                                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                        <FormControl >
                                                            <TextField
                                                                style={{ width: 200 }}
                                                                // error={selectedसर्वोत्तम.match(/[A-Za-z+@#$&%!~]/)}
                                                                id="outlined-adornment-TrainerName"
                                                                value={result[index].विषय}
                                                                label="विषय"
                                                                onChange={(e) => { handleResult(row?.id, "विषय", e.target.value) }}
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                        <FormControl >
                                                            <TextField
                                                                style={{ width: 350 }}
                                                                // error={selectedसर्वोत्तम.match(/[A-Za-z+@#$&%!~]/)}
                                                                id="outlined-adornment-TrainerName"
                                                                value={result[index].निर्णय}
                                                                label="निर्णय"
                                                                onChange={(e) => handleResult(row?.id, "निर्णय", e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                    <center>
                        {
                            reportLoader ? <CircularProgress color="primary" /> :
                                <Button variant="contained" style={{ margin: 20 }} onClick={() => hadleFormSubmit()} >Submit</Button>
                        }
                    </center>
                    <div style={{ backgroundColor: '#3A3A56', display: 'flex', flexDirection: 'row', width: '100%', margin: 0, height: 2 }} />
                    <center>
                        <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)} >Back</Button>
                        <Button variant="contained" style={{ margin: 20 }} onClick={() => window.location.reload()} >Reset</Button>
                    </center>
                </div>
            </Card>

            <Modal
                className={classes.middlePosition}
                open={feedbackError}
                onClose={(e) => {
                    e.preventDefault()
                    setFeedbackError(false);
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>
                            Sorry, Report cannot be created due to no feedback form images available
                            </Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setFeedbackError(!feedbackError);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant="contained" onClick={() => setFeedbackError(!feedbackError)} >OK</Button>
                    </center>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={imageError}
                onClose={(e) => {
                    e.preventDefault()
                    setImageError(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>
                                Cannot Add more than 6 images.
                            </Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setImageError(!imageError);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant="contained" onClick={() => setImageError(!imageError)} >OK</Button>
                    </center>
                </Paper>
            </Modal>
            <Modal
                className={classes.middlePosition}
                open={error}
                onClose={(e) => {
                    e.preventDefault()
                    setError(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>
                                Please make sure to Select the Details and enter All fields .
                            </Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setError(!error);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant="contained" onClick={() => setError(!error)} >OK</Button>
                    </center>
                </Paper>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '70%',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '750px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
        flexWrap: 'wrap'
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
    image: {
        width: 200,
        height: 200
    },
    imageApprove: {
        backgroundColor: 'green',
        color: 'white'
    },
    imageReject: {
        backgroundColor: 'red',
        color: 'white'
    }
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategories: (data) => dispatch(Action.getCategories(data)),
        GenerRateReport: (data) => dispatch(Action.GenerRateReport(data)),
        getSingleTraining: (data) => dispatch(Action.getSingleTraining(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm)
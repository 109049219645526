import { ClassNames } from '@emotion/react';
import { makeStyles } from "@material-ui/styles";
import { connect } from 'react-redux';
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow, 
  TableCell, CircularProgress, Button, TablePagination, Modal,  Typography, Card, IconButton, TextField, 
FormControl, CardContent} from '@material-ui/core';
import { Category, Visibility, Close, Delete, Upload, Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Stack from "@mui/material/Stack";
import axios from 'axios';
import { Box } from '@mui/material';
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Container, Form, Row } from 'react-bootstrap';
import Compressor from 'compressorjs';
import S3 from "react-aws-s3";


const CategoryList = ({ userDetails }) => {

    const classes = useStyles();
    const [categories, setCatgories] = useState([]);
    const [loader, setLoader] = useState(false);

    const [img, setImg] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [imgUploading, setImgUploading] = useState(false);


    const [imgErr, setImgErr] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    const [descErr, setDescErr] = useState(false);
    
    const [ViewData, setViewData] = useState(false);
    const [ViewUser, setViewUser] = useState(false);

    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [editId, setEditId] = useState('');

    const [deleteid, setdeleteid] = useState();
    const [opendeleteuser, setopendeleteuser] = useState();

    //Table Pagination variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };


    useEffect(()=>{
        getAllCategories();
    },[])

    const getAllCategories = async()=>{
      try{
        setLoader(true);
        const res = await fetch(process.env.REACT_APP_BASE_URL + '/trainingcategory/gettrainingcategorylist', {
          method : 'GET'
        })
        const resposne = await res.json();
        setCatgories(resposne);
        setLoader(false);
      }catch(Err){
        console.log("Err while getting training categories", Err);
        setLoader(false);
      }
    }

    const validate =()=>{
      let isValid = true;
      if(!img){
        setImgErr(true);
        isValid = false
      }
      if(!name){
        setNameErr(true);
        isValid = false
      }
      if(!description){
        setDescErr(true);
        isValid = false;
      }
      return isValid;
    }

    const addCategory = async()=>{
      if(validate()){
        try{
          setLoader(true);
          const res = await fetch(process.env.REACT_APP_BASE_URL + '/trainingcategory/inserttrainingcategory',{
            method : 'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
              trainingCategoryName:name,
              description: description,
              imgURL:img
            })
          });

          const responce = await res.json();
          if(responce){
            setLoader(false);
            closeAdd();
            alert('Category added successfully');
            getAllCategories();
          }
          
        }catch(Err){
          console.log("Err while adding category", Err);
          setLoader(false);
        }
      }
     
    }


    const handleEdit = (row)=>{
      setEditId(row?._id);
      setImg(row?.imgURL);
      setName(row.trainingCategoryName);
      setDescription(row.description);
      setOpenEdit(true);
    } 

    const editCategory = async()=>{
      if(validate()){
        try{
          setLoader(true);
          const res = await fetch(process.env.REACT_APP_BASE_URL + '/trainingcategory/trainingcategoryupdate',{
            method : 'PUT',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
              trainingCategory_id:editId,
              trainingCategoryName:name,
              description: description,
              imgURL:img,
              updatedBy:userDetails?._id
            })
          });

          const responce = await res.json();
          if(responce){
            setLoader(false);
            closeAdd();
            alert('Category added successfully');
            getAllCategories();
          }
          
        }catch(Err){
          console.log("Err while adding category", Err);
          setLoader(false);
        }
      }
    }

    const closeAdd = ()=>{
      setOpenAdd(false);
      setOpenEdit(false);
      setImgErr(false);
      setNameErr(false);
      setDescErr(false);
    }

    const handleUpload = async (e) => {
      if (e.target.files.length) {
        const img = URL.createObjectURL(e.target.files[0]);
        if(e.target.files[0].type?.includes("image")){
          uploadImageToS3(e.target.files[0]);
        }else{
          alert("Upload Image")
        }
      }
    }

    
  const uploadImageToS3 = (event) => {
    setImgUploading(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + "/" + 'Category',
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: "https://gsda-images2.s3.ap-south-1.amazonaws.com"
    };

    let fileInput = false;
    const image = event;
    // console.log("image data before compression-------------- ", image);
    if (event) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.9,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_";
          // console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              // setImage(res.location);

              if (res.status === 204) {
                setImgUploading(false);
                // console.log("uploded image", res);
                // console.log("image uploaded successfully!!!!");
                setImg(res.location);
              }
              else {
                setImgUploading(false);
              }
              //   }
            }).catch(err => {
              setImgUploading(false);
              console.error(err);
            });
        },
      });
    }
  };



    const openView = (row) => {
      setViewData(row);
      setViewUser(true);
    };

    const openDelete = (row) => {
      setdeleteid(row);
      setopendeleteuser(true);
    };

    const Deleteuser = async () => {
      axios.put(process.env.REACT_APP_BASE_URL+"/trainingcategory/trainingcategorydelete", {
          updatedBy: userDetails._id,
          trainingCategory_id: deleteid._id,
         })
        .then((responce) => {
          setopendeleteuser(false);
          alert('Deleted successfully')
          getAllCategories();
        })
        .catch((err) => {
          console.log("error in Delete category", err);
        });
    };

  return (
    <div className={`CategoryList my-4 `}>

      <div style={{ display: "flex", alignItems:"center", flexDirection: "row", width: "100%", marginBottom:'20px' }}>
        <Box sx={{ flex: 1 }}>
          <h2 className='mb-0'>Manage Category</h2>
        </Box>
        <Box>
          <div style={{ marginTop: 30, right: 0 }}>
            <Button variant="contained" style={{background:'#3A3A56', color:'#FFF'}} onClick={() => setOpenAdd(true)}>
              <Category style={{ marginRight: 10 }} />
              Add Category
            </Button>
          </div>
        </Box>
      </div>
      
      {loader ? <CircularProgress color="primary" /> :
        <div  className='d-flex justify-content-center'>
          <Paper sx={{ overflow: "hidden", margin:'auto', width:'maxContent' }}>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>SR No.</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Image</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Category Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Min. Docs Approved</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Min. Docs Upload</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Max. Docs Upload</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories?.length > 0 ? (categories
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            <img src={row?.imgURL} alt={row?.trainingCategoryName} width={50}/>
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.trainingCategoryName}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.minDocAproved}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.minDocUpload}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.maxDocUpload}
                          </TableCell>
                          {/* <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.isPublish ? }
                          </TableCell> */}
                          <TableCell style={{ textAlign: "center" }}>
                            <Button
                              variant="contained"
                              style={{ margin: 5 }}
                              onClick={() => openView(row)}
                            >
                              <Visibility style={{ marginRight: 5 }} />
                              {/* View */}
                            </Button>
                      
                            <Button
                            variant="contained"
                            style={{ margin: 5 }}
                            onClick={ ()=>{
                              handleEdit(row);
                            }}>
                            <Edit style={{ marginRight: 5 }} />
                          </Button>

                            <Button
                              onClick={() => {
                                openDelete(row);
                              }}
                              variant="contained"
                              style={{ margin: 5 }}
                            >
                              <Delete style={{ marginRight: 5 }} />
                              {/* Delete */}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={count ? count : 0}
              count={categories?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      }


   {/* Add Category */}
      <Modal
        className={classes.middlePosition}
        open={openAdd}
        onClose={(e) => {
          e.preventDefault();
          closeAdd();
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2} style={{  borderRadius:'12px 12px 0px 0px', marginBottom:'20px'}}>
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Add Category
              </Typography>
            </Stack>
            <IconButton style={{color:'#000'}}
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setOpenAdd(false);
              }}
            >
              <CloseIcon  />
            </IconButton>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
      
              <Container>
                <Row>
                  <Col md={6} className='mb-3 d-flex flex-column'>
                    <Form.Label>Category Image</Form.Label>
                    <Button variant="contained" component="label" style={{background:'#3A3A56', color:'#FFF', marginBottom:'12px'}}>
                      <Upload/>Upload
                      <input accept="image/*"
                       className={classes.input} id="icon-button-file" type="file"
                        onChange={(e) => handleUpload(e)}
                        style={{ display: "none" }} />
                    </Button>
                    {imgErr ? <span style={{ color: 'red', fontSize: 15 }}>* Please Upload Image</span> : null} 
                    {img ? <img src={img} style={{ width: "70px", height: "70px" }} /> : null}
                    {imgUploading ? <CircularProgress /> : null}
                  </Col>

                  <Col md={6} className='mb-3'>
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control placeholder='Enter category name' value={name} onChange={(e)=>setName(e.target.value)}/>
                    {nameErr ? <span style={{ color: 'red', fontSize: 15 }}>* Please enter category name</span> : null}
                  </Col>

                  <Col md={12} className='mb-3'>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" placeholder='Enter category description' rows={4} 
                      value={description} onChange={(e)=>setDescription(e.target.value)}/>
                    { descErr ? <span style={{ color: 'red', fontSize: 15 }}>* Please enter description</span> : null}
                  </Col>
                </Row>
              </Container>
          
            <Button
              variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                addCategory();
              }}
            >
              Add
            </Button>
          </div>
        </Paper>
      </Modal>

   {/* Edit Category */}
   <Modal
        className={classes.middlePosition}
        open={openEdit}
        onClose={(e) => {
          e.preventDefault();
          closeAdd();
        }}>
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2} style={{  borderRadius:'12px 12px 0px 0px', marginBottom:'20px'}}>
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Update Category
              </Typography>
            </Stack>
            <IconButton style={{color:'#000'}}
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setOpenEdit(false);
              }}
            >
              <CloseIcon  />
            </IconButton>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
      
              <Container>
                <Row>
                  <Col md={6} className='mb-3 d-flex flex-column'>
                    <Form.Label>Category Image</Form.Label>
                    <Button variant="contained" component="label" style={{background:'#3A3A56', color:'#FFF', marginBottom:'12px'}}>
                      <Upload/>Upload
                      <input accept="image/*"
                       className={classes.input} id="icon-button-file" type="file"
                        onChange={(e) => handleUpload(e)}
                        style={{ display: "none" }} />
                    </Button>
                    {imgErr ? <span style={{ color: 'red', fontSize: 15 }}>* Please Upload Image</span> : null} 
                    {img ? <img src={img} style={{ width: "70px", height: "70px" }} /> : null}
                    {imgUploading ? <CircularProgress /> : null}
                  </Col>

                  <Col md={6} className='mb-3'>
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control placeholder='Enter category name' value={name} onChange={(e)=>setName(e.target.value)}/>
                    {nameErr ? <span style={{ color: 'red', fontSize: 15 }}>* Please enter category name</span> : null}
                  </Col>

                  <Col md={12} className='mb-3'>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" placeholder='Enter category description' rows={4} 
                      value={description} onChange={(e)=>setDescription(e.target.value)}/>
                    { descErr ? <span style={{ color: 'red', fontSize: 15 }}>* Please enter description</span> : null}
                  </Col>
                </Row>
              </Container>
          
            <Button
              variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                editCategory();
              }}
            >
              Update
            </Button>
          </div>
        </Paper>
      </Modal>


         {/* view model */}
          <Modal
            className={classes.middlePosition}
            open={ViewUser}
            onClose={(e) => {
              e.preventDefault();
              setViewUser(false);
            }}
          >
            <Paper className={classes.profilemodal}>
              <Stack alignItems="center" spacing={2}>
                {/* <Stack>
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => {
                      e.preventDefault();
                      setViewUser(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack> */}
                <Stack>
                  <Card
                    className={classes.card}
                    style={{ backgroundColor: "whitesmoke" }}
                  >
                    <div
                      style={{
                        backgroundColor: "#3A3A56",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        margin: 0,
                      }}
                    >
                      <IconButton style={{ margin: 10, padding: 0 }}>
                        <Typography
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: 700,
                            color: "white",
                            fontSize: 24,
                            backgroundColor: "#3A3A56",
                          }}
                          sx={{}}
                        >
                          View Catgory Details
                        </Typography>
                      </IconButton>
                    </div>
                    <div>
                      <Table>
                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>Category Image</TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                          <img src={ViewData?.imgURL} alt={ViewData?.trainingCategoryName} width={50}/>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            Category Name
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.trainingCategoryName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>Min. Docs Approved</TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData.minDocAproved}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            Min. Docs Upload
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.minDocUpload}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ fontSize: 15 }}>
                            Max. Docs Upload
                          </TableCell>
                          <TableCell style={{ fontSize: 15 }}>
                            {ViewData?.maxDocUpload}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </div>
                    <center>
                      <Button
                        variant="contained"
                        style={{ margin: 20 }}
                        onClick={() => setViewUser(false)}>
                        Back
                      </Button>
                    </center>
                  </Card>
                </Stack>
              </Stack>
            </Paper>
          </Modal>


       {/* delete model */}
       <Modal
        className={classes.middlePosition}
        open={opendeleteuser}
        onClose={(e) => {
          e.preventDefault();
          setopendeleteuser(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            //direction="row"
            // justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Paper className='px-5 py-2'>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    justifyContent={"center"}
                    component="div"
                  >
                    Are You Sure You Want To Delete ?
                  </Typography>
                </Stack>
              </Stack>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  margin: "21px",
                }}
              >
                <Button
                  variant="contained"
                  style={{ margin: 10}}
                  startIcon={<Close />}
                  onClick={(e) => {
                    e.preventDefault();
                    // EdithandleSubmit();
                    setopendeleteuser(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: 10,  }}
                  // endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    Deleteuser();
                    // setopendeleteuser(false);
                    // DeleteTaluka()
                    // setDeleteTaluka(false)
                  }}
                >
                  <Delete style={{ marginRight: 5 }} />
                  Delete
                </Button>
              </div>
            </Paper>
          </Stack>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  middlePosition: {
    marginTop: 100,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profilemodal: {
    // width: "1000px",
    width: "50%",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {}

export default connect(mapStateToProps,mapDispatchToProps)(CategoryList)

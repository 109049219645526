import React, { useState } from "react";
import { connect } from "react-redux";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import { useEffect } from "react";
import * as Action from "../../actions/adminAction";
import { Edit, Visibility, VisibilityOff, Delete } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Alert,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import axios from "axios";
// import makeStyles from "@mui/material";
const User = [
  {
    id: 1,
    label: "admin",
  },
  {
    id: 2,
    label: "district coordinator",
  },
  {
    id: 3,
    label: "trainer",
  },
  {
    id: 4,
    label: "gramsevak",
  },
  {
    id: 3,
    label: "sarpanch",
  },
  {
    id: 3,
    label: "beneficiary",
  },
  {
    id: 3,
    label: "quality checker",
  },
];

const data = [
  {
    PanchyatName: "ADEGAON",
    description: "Implementing Bhujal Yojana",
    TalukaName: "Bhor",
    DistrictName: "Pune",
    SarpanchName: "XYZ",
    GramsevakName: "auyda",
  },
];
export const EditUser = ({
  getAllDistrictData,
  getAllTalukaData,
  getAllgrampanchayatdata,
  editUserData,
  setEditUser,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  //Modal variables
  const [Adduser, setAddUser] = useState(true);
  //data variables
  const [TrainingModuleName, setTrainingModuleName] = useState("");
  const [usertype, setUserType] = useState(editUserData?.userType);
  const [GramPanchayat, setGramPanchayat] = useState(
    editUserData?.currentAddressGrampanchayat
  );
  const [District, setDistrict] = useState(
    editUserData?.currentAddressDistrict
  );
  const [taluka, setTaluka] = useState(editUserData?.currentAddressTaluka);
  const [PermanentGramPanchayat, setPermanentGramPanchayat] = useState(
    editUserData?.permanentAddressGrampanchayat
  );
  const [PermanentDistrict, setPermanentDistrict] = useState(
    editUserData?.permanentAddressDistrict
  );
  const [Permanenttaluka, setPermanentTaluka] = useState(
    editUserData?.permanentAddressTaluka
  );
  const [preferredGramPanchayat, setpreferredGramPanchayat] = useState(
    editUserData?.preferredGrampanchayatName
  );
  const [preferredGrampanchayatid, setpreferredGrampanchayatid] = useState(
    editUserData?.preferredGrampanchayat_id
  );
  const [preferredDistrict, setpreferredDistrict] = useState(
    editUserData.preferredDistrictName
  );
  const [preferredDistrictid, setpreferredDistrictid] = useState(
    editUserData?.preferredDistrict_id
  );
  const [preferredtaluka, setpreferredTaluka] = useState(
    editUserData?.preferredTalukaName
  );
  const [preferredtalukaid, setpreferredTalukaid] = useState(
    editUserData?.preferredTaluka_id
  );
  const [TalukaData, setTalukaData] = useState([]);
  const [GramPanchayatData, setGramPanchayatData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);

  const [firstName, setFirstName] = useState(editUserData?.firstName);
  const [middleName, setMiddleName] = useState(editUserData?.middleName);
  const [lastName, setLastName] = useState(editUserData?.lastName);
  const [contact, setContact] = useState(editUserData?.contactNo);
  const [whatsup, setwhatsup] = useState(editUserData?.whatsappNo);
  const [email, setEmail] = useState(editUserData?.emailId);
  const [currentAdd, setCurrentAdd] = useState(
    editUserData?.currentAddressLine1
  );
  const [currentAddlinetwo, setcurrentAddlinetwo] = useState(
    editUserData?.currentAddressLine2
  );
  const [permanentAddlinetwo, setpermanentAddlinetwo] = useState(
    editUserData?.permanentAddressLine2
  );
  //   const [profilePicURL, setprofilePicURL] = useState("");
  const [permanentAdd, setPermanentAdd] = useState(
    editUserData?.permanentAddressLine1
  );
  const [permanentpincode, setpermanentPincode] = useState(
    editUserData?.permanentAddressPincode
  );
  const [pincode, setPincode] = useState(editUserData?.currentAddressPincode);
  const [currentstate, setcurrentstate] = useState(
    editUserData?.currentAddressState
  );
  const [permanentstate, setpermanentstate] = useState(
    editUserData?.permanentAddressState
  );
  const [password, setPassword] = useState("");
  const [imgArray, setImgArray] = useState(editUserData?.profilePicURL);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setloading] = useState(false);
  const [imgUploadingError, setImgUploadingError] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);
  //Error variables
  const [error, setError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [fnameError, setfnameError] = useState(false);
  const [lnameError, setlnameError] = useState(false);
  const [contactError, setcontactError] = useState(false);
  const [whatsupcontactError, setwhatsupcontactError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [currAddError, setcurrAddError] = useState(false);
  const [currAddtwoError, setcurrAddtwoError] = useState(false);
  const [currAddgrampanchaytError, setcurrAddgrampanchaytError] =
    useState(false);
  const [currAddtalukaError, setcurrAddtalukaError] = useState(false);
  const [currAdddistrictError, setcurrAdddistrictError] = useState(false);
  const [currAddstateError, setcurrAddstateError] = useState(false);
  const [preffAddtalukaError, setpreffAddtalukaError] = useState(false);
  const [preffAdddistrictError, setpreffAdddistrictError] = useState(false);
  const [preffAddgrampanchayatError, setpreffAddgrampanchayatError] =
    useState(false);
  const [PermaAddError, setPermaAddError] = useState(false);
  const [pinError, setpinError] = useState(false);
  const [usertypeError, setusertypeError] = useState(false);
  const [distError, setdistError] = useState(false);
  const [talukaError, settalukaError] = useState(false);
  const [GramPanchayatError, setGramPanchayatError] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getDistrict = async () => {
    const result = await getAllDistrictData();
    if (result !== "No data found") {
      setDistrictData(result);
    }
  };

  const getTaluka = async () => {
    const result = await getAllTalukaData();
    if (result !== "No data found") {
      setTalukaData(result);
    }
  };
  const getGrampanchayat = async () => {
    const result = await getAllgrampanchayatdata();
    if (result !== "No data found") {
      setGramPanchayatData(result);
    }
  };

  useEffect(() => {
    getDistrict();
    getTaluka();
    getGrampanchayat();
  }, []);
  const validate = () => {
    let IsformValid = true;

    if (!firstName) {
      IsformValid = false;
      setfnameError(true);
    } else if (firstName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (middleName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!lastName) {
      IsformValid = false;
      setlnameError(true);
    } else if (lastName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!contact) {
      IsformValid = false;
      setcontactError(true);
    } else if (
      contact.match(/[A-Za-z+@#$&%!~]/) ||
      (contact.length !== 10 && contact.length !== 0)
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!email) {
      IsformValid = false;
      setEmailError(true);
    } else if (
      !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) &&
      email.length !== 0
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!password) {
      IsformValid = false;
      setPassError(true);
    }

    if (!currentAdd) {
      IsformValid = false;
      setcurrAddError(true);
    }

    if (!pincode) {
      IsformValid = false;
      setpinError(true);
    } else if (
      pincode.match(/[A-Za-z+@#$&%!~]/) ||
      (pincode.length !== 6 && pincode.length !== 0)
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!usertype) {
      IsformValid = false;
      setusertypeError(true);
    }

    if (!District) {
      IsformValid = false;
      setcurrAdddistrictError(true);
    }

    if (!taluka) {
      IsformValid = false;
      setcurrAddtalukaError(true);
    }

    if (!GramPanchayat) {
      IsformValid = false;
      setcurrAddgrampanchaytError(true);
    }
    if (!whatsup) {
      setwhatsupcontactError(true);
    }
    if (!currentAddlinetwo) {
      setcurrAddtwoError(true);
    }
    if (!currentstate) {
      setcurrAddstateError(true);
    }
    if (!taluka) {
      setcurrAddtalukaError(true);
    }
    if (!preferredGramPanchayat) {
      setpreffAddgrampanchayatError(true);
    }
    if (!preferredtaluka) {
      setpreffAddtalukaError(true);
    }
    if (!preferredDistrict) {
      setpreffAdddistrictError(true);
    }
    return IsformValid;
  };
  const uploadImageToS3 = (event) => {
    setImgUploading(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + "/" + TrainingModuleName,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: "https://gsda-images2.s3.ap-south-1.amazonaws.com",
    };

    let fileInput = false;
    const image = event;
    // console.log("image data before compression-------------- ", image);
    if (event) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.9,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_";
          // console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              // setImage(res.location);

              if (res.status === 204) {
                setImgUploading(false);
                // console.log("uploded image", res);
                // console.log("image uploaded successfully!!!!");
                setImgArray(res.location);
                setLoader(false);
              } else {
                setImgUploadingError(true);
                setLoader(false);
              }
              //   if (type === "ProfilePic") {
              //     // if (image !== null && image !== userDetails?.profilePhotoURL) {
              //     //   // updateProfilePic({ profilePhotoURL: res.location, user_Id: userDetails?._id })
              //     // }
              //   } else if (type === "Logo1") {
              //     // setlogo1(res.location)
              //   } else if (type === "Logo2") {
              //     // setlogo2(res.location)
              //   }
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
      // console.log("--------------- Upload image -----------");
    }
  };
  const handleChange = async (e) => {
    // console.log("Changing Profile pic", e.target.files);
    // if (e.target.files.length > categoryName.qty)
    //   alert("Cannot upload more than 10 Images")
    if (e.target.files.length) {
      const img = URL.createObjectURL(e.target.files[0]);
      // console.log("Files Type", e.target.files[0]);
      setLoader(true);
      uploadImageToS3(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    setfnameError(false);
    setlnameError(false);
    setEmailError(false);
    setcontactError(false);
    setpinError(false);
    setPassError(false);
    setcurrAddError(false);
    setPermaAddError(false);
    setusertypeError(false);
    setdistError(false);
    settalukaError(false);
    setGramPanchayatError(false);
    setcurrAddtalukaError(false);
    setcurrAddgrampanchaytError(false);
    setcurrAddtwoError(false);
    setwhatsupcontactError(false);
    setGramPanchayatError(false);
    setcurrAddstateError(false);
    setpreffAdddistrictError(false);
    setpreffAddtalukaError(false);
    setpreffAddgrampanchayatError(false);
    setcurrAdddistrictError(false);
    if (validate()) {
      //   const firstName = firstName;
      //    const middleName = middleName;
      //   const lastName = lastName;
      const contactNo = contact;
      const emailId = email;

      const whatsappNo = whatsup;
      const currentAddressLine1 = currentAdd;
      const currentAddressLine2 = currentAddlinetwo;
      const currentAddressPincode = pincode;

      const currentAddressGrampanchayat = GramPanchayat;
      const currentAddressState = currentstate;
      const currentAddressTaluka = taluka;

      const currentAddressDistrict = District;
      const permanentAddressLine1 = permanentAdd;
      const permanentAddressLine2 = permanentAddlinetwo;
      const permanentAddressPincode = permanentpincode;
      const permanentAddressTaluka = Permanenttaluka;
      const permanentAddressDistrict = PermanentDistrict;
      const permanentAddressState = permanentstate;
      const permanentAddressGrampanchayat = PermanentGramPanchayat;
      const preferredGrampanchayat_id = preferredGrampanchayatid;
      const preferredGrampanchayatName = preferredGramPanchayat;
      const preferredTalukaName = preferredtaluka;
      const preferredTaluka_id = preferredtalukaid;
      const preferredDistrict_id = preferredDistrictid;
      const preferredDistrictName = preferredDistrict;
      const profilePicURL = imgArray;
      const userType = usertype?.label;
      const user_id = editUserData?._id;
      //   const password = password;
      setloading(true);
      const userData = {
        firstName,
        middleName,
        lastName,
        contactNo,
        whatsappNo,
        emailId,
        currentAddressLine1,
        currentAddressLine2,
        currentAddressPincode,
        currentAddressTaluka,
        currentAddressDistrict,
        currentAddressState,
        currentAddressGrampanchayat,
        permanentAddressLine1,
        permanentAddressLine2,
        permanentAddressPincode,
        permanentAddressTaluka,
        permanentAddressDistrict,
        permanentAddressState,
        permanentAddressGrampanchayat,
        preferredGrampanchayat_id,
        preferredGrampanchayatName,
        preferredTaluka_id,
        preferredTalukaName,
        preferredDistrict_id,
        preferredDistrictName,
        password,
        profilePicURL,
        userType,
        user_id,
      };

      await axios
        .put(process.env.REACT_APP_BASE_URL+"/user/updateuUser", userData)
        .then((responce) => {
          if (responce.data) {
            navigate("/admin/user-list");
          }
          // console.log("responce from add user", responce.data);
        })
        .catch((err) => {
          setloading(false);
          // console.log("error in add user", err);
        });
      // console.log("Form Valid");
    } else {
      // console.log("Form Invalid");
    }
  };
  return (
    <div>
      {loading ? (
        <div
          style={{
            height: "100vh",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />{" "}
        </div>
      ) : (
        <div className={classes.main}>
          <Typography
            variant="h3"
            sx={{ marginTop: 7, fontWeight: 600 }}
            component="h2"
          >
            EDIT USER
          </Typography>

          <Button
            sx={{ marginTop: "40px" }}
            variant="contained"
            endIcon={<SendIcon />}
            onClick={(e) => {
              navigate("/admin/user-list");
            }}
          >
            Go back
          </Button>
          <div className={classes.middlePosition}>
            <Paper className={classes.profilemodal}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        id="outlined-adornment-fname"
                        label="First Name"
                        error={firstName.trim().match(/[0-9+@#$&%!~]/)}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        helperText={
                          fnameError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter First Name
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={middleName.trim().match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-middle"
                        label="Middle-Name"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={lastName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-lname"
                        label="Last-Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        helperText={
                          lnameError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter last Name
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={
                          !email.match(
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                          ) && email.length !== 0
                        }
                        id="outlined-adornment-TrainerName"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        helperText={
                          emailError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter email
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        id="outlined-adornment-TrainerName"
                        label="Current-Address line 1"
                        value={currentAdd}
                        onChange={(e) => setCurrentAdd(e.target.value)}
                        helperText={
                          currAddError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter current Address line 1
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        id="outlined-adornment-TrainerName"
                        label="Current-Address line 2"
                        value={currentAddlinetwo}
                        onChange={(e) => setcurrentAddlinetwo(e.target.value)}
                        helperText={
                          currAddtwoError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter current Address line 2
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        current address GramPanchayat
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="current address GramPanchayat"
                        value={GramPanchayat}
                        onChange={(e) =>
                          setGramPanchayat(e.target.value.grampanchayatName)
                        }
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {GramPanchayatData !== "No data  found" &&
                        GramPanchayatData.length > 0
                          ? GramPanchayatData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.grampanchayatName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>

                      {currAddgrampanchaytError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please select current address grampanchayat
                        </span>
                      ) : null}
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        current address District
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="current address District"
                        value={District}
                        onChange={(e) =>
                          setDistrict(e.target.value.districtName)
                        }
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {DistrictData !== "No data  found" &&
                        DistrictData.length > 0
                          ? DistrictData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.districtName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {currAdddistrictError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please select district data
                        </span>
                      ) : null}
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        current address Taluka
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="current address Taluka"
                        value={taluka}
                        onChange={(e) => setTaluka(e.target.value.talukaName)}
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {TalukaData !== "No data  found" &&
                        TalukaData.length > 0
                          ? TalukaData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.talukaName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {currAddtalukaError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please select current address taluka
                        </span>
                      ) : null}
                    </FormControl>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        id="outlined-adornment-TrainerName"
                        error={firstName.trim().match(/[0-9+@#$&%!~]/)}
                        label="current address state"
                        value={currentstate}
                        onChange={(e) => setcurrentstate(e.target.value)}
                        helperText={
                          currAddstateError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter current Address state
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={
                          pincode.match(/[A-Za-z+@#$&%!~]/) ||
                          (pincode.length !== 6 && pincode.length !== 0)
                        }
                        id="outlined-adornment-TrainerName"
                        label="current address Pin-Code "
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        helperText={
                          pinError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter current address pincode
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>

                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        id="outlined-adornment-TrainerName"
                        label="Permanent-Address line 1"
                        value={permanentAdd}
                        onChange={(e) => setPermanentAdd(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        id="outlined-adornment-TrainerName"
                        label="permanent Address line 2"
                        value={permanentAddlinetwo}
                        onChange={(e) => setpermanentAddlinetwo(e.target.value)}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={
                          permanentpincode.match(/[A-Za-z+@#$&%!~]/) ||
                          (pincode.length !== 6 && pincode.length !== 0)
                        }
                        id="outlined-adornment-TrainerName"
                        label="permanent address Pin-Code "
                        value={permanentpincode}
                        onChange={(e) => setpermanentPincode(e.target.value)}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={firstName.trim().match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="permanent address state "
                        value={permanentstate}
                        onChange={(e) => setpermanentstate(e.target.value)}
                      />
                    </FormControl>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        Permanent GramPanchayat
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="Permanent GramPanchayat"
                        value={PermanentGramPanchayat}
                        onChange={(e) =>
                          setPermanentGramPanchayat(
                            e.target.value.grampanchayatName
                          )
                        }
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {GramPanchayatData !== "No data  found" &&
                        GramPanchayatData.length > 0
                          ? GramPanchayatData.map((option) => (
                              <MenuItem
                                key={option._id}
                                value={option}
                                selected={PermanentGramPanchayat}
                              >
                                {option.grampanchayatName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        Permanent District
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="Permanent District"
                        value={PermanentDistrict}
                        onChange={(e) =>
                          setPermanentDistrict(e.target.value.districtName)
                        }
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {DistrictData !== "No data  found" &&
                        DistrictData.length > 0
                          ? DistrictData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.districtName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        Permanent Taluka
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="Permanent Taluka"
                        value={Permanenttaluka}
                        onChange={(e) =>
                          setPermanentTaluka(e.target.value.talukaName)
                        }
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {TalukaData !== "No data  found" &&
                        TalukaData.length > 0
                          ? TalukaData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.talukaName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        preferred GramPanchayat
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="preferred GramPanchayat"
                        value={preferredGramPanchayat}
                        onChange={(e) => {
                          setpreferredGramPanchayat(
                            e.target.value.grampanchayatName
                          );
                          setpreferredGrampanchayatid(e.target.value._id);
                        }}
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {GramPanchayatData !== "No data  found" &&
                        GramPanchayatData.length > 0
                          ? GramPanchayatData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.grampanchayatName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {preffAddgrampanchayatError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please select preffered address taluka
                        </span>
                      ) : null}
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        preferred District
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="preferred District"
                        value={preferredDistrict}
                        onChange={(e) => {
                          setpreferredDistrict(e.target.value.districtName);
                          setpreferredDistrictid(e.target.value._id);
                        }}
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {DistrictData !== "No data  found" &&
                        DistrictData.length > 0
                          ? DistrictData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.districtName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {preffAdddistrictError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please select preffered address district
                        </span>
                      ) : null}
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        preferred Taluka
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="preferred Taluka"
                        value={preferredtaluka}
                        onChange={(e) => {
                          setpreferredTaluka(e.target.value.talukaName);
                          setpreferredTalukaid(e.target.value._id);
                        }}
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {TalukaData !== "No data  found" &&
                        TalukaData.length > 0
                          ? TalukaData.map((option) => (
                              <MenuItem key={option._id} value={option}>
                                {option.talukaName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {preffAddtalukaError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please select preffered address taluka
                        </span>
                      ) : null}
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={
                          contact.match(/[A-Za-z+@#$&%!~]/) ||
                          (contact.length !== 10 && contact.length !== 0)
                        }
                        id="outlined-adornment-TrainerName"
                        label="Contact No"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                        helperText={
                          contactError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter last Name
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        error={
                          whatsup.match(/[A-Za-z+@#$&%!~]/) ||
                          (whatsup.length !== 10 && whatsup.length !== 0)
                        }
                        id="outlined-adornment-TrainerName"
                        label="Whatsup Number"
                        value={whatsup}
                        onChange={(e) => setwhatsup(e.target.value)}
                        helperText={
                          whatsupcontactError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter whatsup Number
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <TextField
                        style={{ width: 350 }}
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => {
                                  setShowPassword(!showPassword);
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={
                          passError ? (
                            <span style={{ color: "red", fontSize: 15 }}>
                              *Please Enter password
                            </span>
                          ) : null
                        }
                      />
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                      <InputLabel id="outlined-adornment-bank">
                        user type
                      </InputLabel>
                      <Select
                        style={{ width: 350 }}
                        id="outlined-adornment-bank"
                        label="User-Type"
                        value={usertype}
                        onChange={(e) => setUserType(e.target.value)}
                        MenuProps={{
                          style: {
                            maxHeight: 400,
                          },
                        }}
                      >
                        {User !== "No data  found" && User.length > 0
                          ? User.map((option) => (
                              <MenuItem key={option.id} value={option}>
                                {option.label}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {lnameError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please select user type
                        </span>
                      ) : null}
                    </FormControl>
                    {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
                <TextField
                  style={{ width: 350 }}
                  id="outlined-adornment-TrainerName"
                  label="Profile picture"
                  value={profilePicURL}
                  onChange={(e) => setprofilePicURL(e.target.value)}
                  // helperText={
                  //   lnameError ? (
                  //     <span style={{ color: "red", fontSize: 15 }}>
                  //       *Please Enter last Name
                  //     </span>
                  //   ) : null
                  // }
                />
              </FormControl> */}
                    <FormControl sx={{ m: 1, marginRight: 3, marginTop: 4 }}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Button variant="contained" component="label">
                          Upload Profile file
                          <input
                            accept={
                              TrainingModuleName
                                ? TrainingModuleName.type
                                : "image/*"
                            }
                            className={classes.input}
                            trainingModule_id="icon-button-file"
                            type="file"
                            onChange={(e) => handleChange(e)}
                            style={{ display: "none" }}
                          />
                        </Button>
                        {imageError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            * Please Upload Image
                          </span>
                        ) : null}
                        {imgArray ? (
                          <img
                            src={imgArray}
                            style={{ width: "80px", height: "80px" }}
                          />
                        ) : null}
                        {loader ? <CircularProgress /> : null}
                      </Stack>
                    </FormControl>
                  </div>
                </CardContent>
                {error ? (
                  <Alert
                    severity="warning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please fill requested form properly
                  </Alert>
                ) : null}
                <Button
                  variant="contained"
                  style={{ margin: 10 }}
                  endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  Update User
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  card: {
    width: "800px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "100%",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));
const mapStateToProps = (state) => {
  return {
    editUserData: state.admin.editUserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getAllTalukaData: () => dispatch(Action.getAllTalukaData()),
    getAllgrampanchayatdata: () => dispatch(Action.getAllgrampanchayatdata()),
    setEditUser: () => dispatch(Action.setEditUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);

import React, { useState } from "react";
import { ArrowBackIosNew } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Paper, CardContent, IconButton, TextField, FormControl, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";


export const AddBatches = () => {

    const classes = useStyles();
    const navigate = useNavigate();

    //data variables
    const [batchname, setBatcheName] = useState('')
    const [batchcode, setBatchCode] = useState('')
    const [batchcount, setBatchCount] = useState('')
    const [startdate, setStartDate] = useState('')
    const [enddate, setEndDate] = useState('')
    const [assessmentdate, setAssessmentDate] = useState('')
    const [loading, setloading] = useState(false);

    //Error variables
    const [error, setError] = useState(false);
    const [batchnameerror, setBatcheNameError] = useState(false)
    const [batchcodeerror, setBatchCodeError] = useState(false)
    const [batchcounterror, setBatchCountError] = useState(false)
    const [startdateerror, setStartDateError] = useState(false)
    const [enddateerror, setEndDateError] = useState(false)
    const [assessmentdaterror, setAssessmentDateError] = useState(false)

    // for validation
    const validate = () => {
        let IsformValid = true;

        if (!batchname) {
            IsformValid = false;
            setBatcheNameError(true);
        }

        if (!batchcode) {
            IsformValid = false;
            setBatchCodeError(true);
        }

        if (!batchcount) {
            IsformValid = false;
            setBatchCountError(true);
        } else if (!/^\d+$/.test(batchcount)) {
            IsformValid = false;
            setError(true);
        }

        if (!startdate) {
            IsformValid = false;
            setStartDateError(true);
        }

        if (!enddate) {
            IsformValid = false;
            setEndDateError(true);
        }

        if (!assessmentdate) {
            IsformValid = false;
            setAssessmentDateError(true);
        }

        return IsformValid;
    };


    // for add batches
    const handleSubmit = async () => {
        setBatcheNameError(false);
        setBatchCodeError(false);
        setBatchCountError(false);
        setStartDateError(false);
        setEndDateError(false);
        setAssessmentDateError(false);
        if (validate()) {
            const payload = {
                batchName: batchname,
                batchCode: batchcode,
                batchCount: batchcount,
                batchStartDate: startdate,
                batchEndDate: enddate,
                batchAssessmentDate: assessmentdate,
                createdBy: ""
            }
            setloading(true);
            await axios
                .post(process.env.REACT_APP_BASE_URL + "/batch/insertbatch", payload)
                .then((response) => {
                    if (response.status === 200) {
                        alert("Batches Added Successfully");
                        navigate("/admin/batches-list");
                        setloading(false);
                    } else {
                        setloading(false)
                    }
                })
                .catch((err) => {
                    console.log("Error:", err)
                    setloading(false);
                });
        } else {
            console.log("Form Invalid");
        }
    };

    return (
        <center>
            <div className={classes.mainClass}>
                <Typography
                    variant="h3"
                    sx={{ marginTop: 7, fontWeight: 600 }}
                    component="h2"
                >
                    ADD Batches
                </Typography>

                <Button
                    sx={{ marginTop: 2 }}
                    variant="contained"
                    startIcon={<ArrowBackIosNew />}
                    onClick={(e) => {
                        navigate("/admin/batches-list");
                    }}
                >
                    Go back
                </Button>

                <div>
                    <Paper className={classes.profilemodal}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <CardContent
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#3A3A56', color: 'white', padding: 10, letterSpacing: '1px' }}>Batches Details</Typography>
                                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                                        <TextField
                                            style={{ width: 250 }}
                                            id="outlined-adornment-TrainerName"
                                            label="Batch Name"
                                            value={batchname}
                                            onChange={(e) => setBatcheName(e.target.value)}
                                            helperText={
                                                batchnameerror ? (
                                                    <span style={{ color: "red", fontSize: 15 }}>
                                                        *Please Enter Batches Name
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                                        <TextField
                                            style={{ width: 250 }}
                                            id="outlined-adornment-TrainerName"
                                            label="Batch Code"
                                            value={batchcode}
                                            onChange={(e) => setBatchCode(e.target.value)}
                                            helperText={
                                                batchcodeerror ? (
                                                    <span style={{ color: "red", fontSize: 15 }}>
                                                        *Please Enter Batches Code
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    </FormControl>
                                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                                        <TextField
                                            style={{ width: 250 }}
                                            id="outlined-adornment-TrainerName"
                                            label="Batche Count"
                                            value={batchcount}
                                            onChange={(e) => setBatchCount(e.target.value)}
                                            helperText={
                                                batchcounterror ? (
                                                    <span style={{ color: "red", fontSize: 15 }}>
                                                        *Please Enter Batch Count
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}></div>

                                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                                        <TextField
                                            style={{ width: 250 }}
                                            id="outlined-adornment-StartDate"
                                            label="Start Date"
                                            type="date"
                                            value={startdate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={
                                                startdateerror ? (
                                                    <span style={{ color: "red", fontSize: 15 }}>
                                                        *Please select start date
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                                        <TextField
                                            style={{ width: 250 }}
                                            id="outlined-adornment-StartDate"
                                            label="End Date"
                                            type="date"
                                            value={enddate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={
                                                enddateerror ? (
                                                    <span style={{ color: "red", fontSize: 15 }}>
                                                        *Please select end date
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    </FormControl>

                                    <FormControl sx={{ m: 1, marginRight: 3 }}>
                                        <TextField
                                            style={{ width: 250 }}
                                            id="outlined-adornment-StartDate"
                                            label="Assessment Date"
                                            type="date"
                                            value={assessmentdate}
                                            onChange={(e) => setAssessmentDate(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={
                                                assessmentdaterror ? (
                                                    <span style={{ color: "red", fontSize: 15 }}>
                                                        *Please select assessment date
                                                    </span>
                                                ) : null
                                            }
                                        />
                                    </FormControl>
                                </div>

                            </CardContent>
                            {error ? (
                                <Alert
                                    severity="warning"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setError(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    Please fill requested form properly
                                </Alert>
                            ) : null}
                            <Button
                                variant="contained"
                                style={{ margin: 10 }}
                                endIcon={<SendIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Paper>
                </div>
            </div>
        </center >
    );
};
const useStyles = makeStyles((theme) => ({
    mainClass: {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    card: {
        width: "800px",
        borderRadius: "20px !important",
        margin: "20px",
        padding: "20px",
    },
    passmodal: {
        width: "450px",
        background: "white",
        borderRadius: "20px !important",
        margin: "20px",
        padding: "20px",
    },
    profilemodal: {
        width: 'intrinsic',
        background: "white",
        borderRadius: "20px !important",
        margin: "20px",
        padding: "20px",
    },
    middlePosition: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column'
    },
    input: {
        width: "70%",
        background: "white",
        marginTop: "20px",
        padding: "5px 15px",
    },
}));


export default AddBatches;

import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import * as Action from "../../actions/adminAction"
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Spinner, Button, Card } from "react-bootstrap";
import { Visibility } from "@mui/icons-material";


export const Dashboard = ({ getDashboardCounts, setActiveList }) => {

    const classes = useStyles()
    const navigate = useNavigate()

    const [loader, setLoader] = useState(false)

    //data variables
    const [counts, setCounts] = useState([]);
    
  const cardsData = [
    {
      title: "All Assigned Trainers",
      count: 'allAssignedTrainerCount',
      route:"/admin/assigned-trainer-list",
    //   image: User 
    },
    {
      title: "All Approved Training",
      count: 'approvalTrainingCount',
      route:"/admin/Approved-document",
    //   image: DashConsumer 
    },
    {
      title: "Total Districts",
      count: 'distric',
      route:"/admin/all-district-list",
    //   image: DashDept 
    },
    {
      title: "Total Taluka",
      count: 'taluka',
      route:"/admin/all-taluka-list",
    },
    {
      title: "Total Trainers",
      count: 'trainer',
      route:"/admin/assign-trainer",
    },
    {
      title: "Total Training categories",
      count: 'trainingCategory',
      route:"/admin/training-category",
    },
    {
      title: " Total Training Modules",
      count: 'trainingModul',
      route:"/admin/training-module",
    },
  ];

    useEffect(() => {
        getDashboardCount();
    }, [])

    const getDashboardCount = async () => {
        setLoader(true);
        const result = await getDashboardCounts();
        if (result) {
            setCounts(result)
            setLoader(false)
            // console.log("Counts", result)
        } else {
            setLoader(false)
        }
    }

    const handleClick =(route)=>{
        console.log("route", route);
        navigate(route);
        setActiveList(route);
    }

    return (
        <div className='dashboard mt-5'>
                    <Container>
                        <Row>
                        {
                            cardsData?.map((row, index) => (
                            <Col key={index} md={3} className='mb-5'>
                                <Card className="hover-card">
                                <Card.Body>
                                    { 
                                    loader? <Spinner animation="grow" variant="secondary" className='mb-2'/> : 
                                    <h2>{counts[row.count]}</h2>
                                    }
                                    <h5>{row.title}</h5>
                                    <Button onClick={()=>handleClick(row?.route)} >
                                        <Visibility/>
                                    </Button>
                                </Card.Body>
                                {/* <div className="card-image" style={{ backgroundImage: `url(${row.image})` }}></div> */}
                                </Card>
                            </Col>
                        ))}
                        </Row>
                    </Container>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '1200px',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '1400px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        getDashboardCounts: () => dispatch(Action.getDashboardCounts()),
        setActiveList: (data) => dispatch(Action.setActiveList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Alert } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from 'react';

const TrainerData = [
  {
    Name: 'ABC',
    Mobile: '8529637410',
    Email: 'ABC@123gmail.com',
    Address: 'Pune'
  },
  {
    Name: 'PQR',
    Mobile: '789456130',
    Email: 'PQR@123gmail.com',
    Address: 'Pune'
  },
  {
    Name: 'XYZ',
    Mobile: '3216974036',
    Email: 'XYZ@123gmail.com',
    Address: 'Pune'
  },
  {
    Name: 'LMN',
    Mobile: '789456130',
    Email: 'LMN@123gmail.com',
    Address: 'Pune'
  },
]

export const AddUpdateTrainerList = ({ }) => {
  const navigate = useNavigate();
  // hooks and Props
  const classes = useStyles()

  //data variables
  const [TrainerName, setTrainerName] = useState('')
  const [TrainerMobile, setTrainerMobile] = useState('')
  const [TrainerEmail, setTrainerEmail] = useState('')
  const [TrainerAdderess, setTrainerAdderess] = useState('')
  const [trainerData, setTrainerData] = useState([])

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Modal variables
  const [openTrainer, setOpenTrainer] = useState(false)
  const [EditTrainer, setEditTrainer] = useState(false)

  //Error variables
  const [NameError, setNameError] = useState(false)
  const [MobileError, setMobileError] = useState(false)
  const [EmailError, setEmailError] = useState(false)
  const [AddError, setAddError] = useState(false)
  const [Error, setError] = useState(false)

  useEffect(() => {
    getTrainerData()
  }, [])

  const getTrainerData = async () => {
    setTrainerData(TrainerData)
  }

  const foramvaliadate = () => {
    let IsformValid = true;

    if (!TrainerName) {
      IsformValid = false;
      setNameError(true)
    } else if (TrainerName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true)
    }

    if (!TrainerMobile) {
      IsformValid = false;
      setMobileError(true)
    } else if (TrainerMobile.match(/[A-Za-z+@#$&%!~]/) || TrainerMobile.length !== 10 && TrainerMobile.length !== 0) {
      IsformValid = false;
      setError(true)
    }

    if (!TrainerEmail) {
      IsformValid = false;
      setEmailError(true)
    } else if (!TrainerEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) && TrainerEmail.length !== 0) {
      IsformValid = false;
      setError(true)
    }

    if (!TrainerAdderess) {
      IsformValid = false;
      setAddError(true)
    } else if (TrainerAdderess.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true)
    }

    return IsformValid;
  }

  const handleAdd = async () => {
    setNameError(false)
    setMobileError(false)
    setEmailError(false)
    setAddError(false)

    if (foramvaliadate() && Error === false) {
      // console.log("Form Valid")
      // console.log("Trainer Name", TrainerName)
      // console.log("Trainer Mobile", TrainerMobile)
      // console.log("Trainer Email", TrainerEmail)
      // console.log("Trainer Address", TrainerAdderess)
      setOpenTrainer(false)
    } else {
      // console.log("Form Invalid")
    }
  }

  const openEdit = (row) => {
    setTrainerName(row?.Name)
    setTrainerMobile(row?.Mobile)
    setTrainerEmail(row?.Email)
    setTrainerAdderess(row?.Address)
    setEditTrainer(true)
  }

  const handleEdit = async () => {
    setNameError(false)
    setMobileError(false)
    setEmailError(false)
    setAddError(false)

    if (foramvaliadate() && Error === false) {
      // console.log("Form Valid")
      // console.log("Trainer Name", TrainerName)
      // console.log("Trainer Mobile", TrainerMobile)
      // console.log("Trainer Email", TrainerEmail)
      // console.log("Trainer Address", TrainerAdderess)
      setEditTrainer(false)
    } else {
      // console.log("Form Invalid")
    }
  }

  return (
    <div className={classes.main}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <h1>All Trainer List</h1>
        </Box>
        {/* <Box >
          <div style={{ marginTop: 30, right: 0 }}>
            <Button variant="contained" onClick={() => {setOpenTrainer(true);navigate("/admin/addTrainerModule");}} ><PersonAddRounded style={{ marginRight: 10 }} />Add Trainer</Button>
          </div>
        </Box> */}
      </div>
      <Paper sx={{ overflow: 'hidden', width: '90%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>SR No.</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Name</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Mobile</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Email-Id</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Address</strong></TableCell>
                <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                trainerData.length > 0 ? trainerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Name}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Mobile}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Email}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.Address}</TableCell>
                      <TableCell style={{ fontSize: 16, textAlign: 'center' }}>
                        <Button variant='contained' onClick={() => openEdit(row)}>Edit</Button>
                      </TableCell>
                    </TableRow>
                  )
                })
                  : <TableRow>NO Data Found</TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={trainerData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Add Trainer */}
      <Modal
        className={classes.middlePosition}
        open={openTrainer}
        onClose={(e) => {
          e.preventDeafault();
          setOpenTrainer(false)
        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Add Trainer</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenTrainer(false)
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <CardContent style={{ display: 'flex', width: '90%', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainerName.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="Trainer Name"
                    value={TrainerName}
                    onChange={(e) => setTrainerName(e.target.value)}
                    helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainerMobile.match(/[A-Za-z+@#$&%!~]/) || TrainerMobile.length !== 10 && TrainerMobile.length !== 0}
                    id="outlined-adornment-TrainerName"
                    label="Mobile Number"
                    value={TrainerMobile}
                    onChange={(e) => setTrainerMobile(e.target.value)}
                    helperText={MobileError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Mobile No.</span> : null}
                  />
                </FormControl>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={!TrainerEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) && TrainerEmail.length !== 0}
                    id="outlined-adornment-TrainerName"
                    label="Email-Id"
                    value={TrainerEmail}
                    onChange={(e) => setTrainerEmail(e.target.value)}
                    helperText={EmailError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer's Email-Id</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainerAdderess.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerAdderess"
                    label="Address"
                    value={TrainerAdderess}
                    onChange={(e) => setTrainerAdderess(e.target.value)}
                    helperText={AddError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer's Address</span> : null}
                  />
                </FormControl>
              </div>
            </CardContent>
            {Error ? <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setError(false)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please fill requested form properly
            </Alert> : null}
            <Button variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                handleAdd()
              }} >
              ADD Trainer
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Update Trainer */}
      <Modal
        className={classes.middlePosition}
        open={EditTrainer}
        onClose={(e) => {
          e.preventDeafault();
          setEditTrainer(false)
        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Update Trainer</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setEditTrainer(false)
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <CardContent style={{ display: 'flex', width: '90%', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainerName.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="Trainer Name"
                    value={TrainerName}
                    onChange={(e) => setTrainerName(e.target.value)}
                    helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainerMobile.match(/[A-Za-z+@#$&%!~]/) || TrainerMobile.length !== 10 && TrainerMobile.length !== 0}
                    id="outlined-adornment-TrainerName"
                    label="Mobile Number"
                    value={TrainerMobile}
                    onChange={(e) => setTrainerMobile(e.target.value)}
                    helperText={MobileError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Mobile No.</span> : null}
                  />
                </FormControl>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={!TrainerEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) && TrainerEmail.length !== 0}
                    id="outlined-adornment-TrainerName"
                    label="Email-Id"
                    value={TrainerEmail}
                    onChange={(e) => setTrainerEmail(e.target.value)}
                    helperText={EmailError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer's Email-Id</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainerAdderess.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerAdderess"
                    label="Address"
                    value={TrainerAdderess}
                    onChange={(e) => setTrainerAdderess(e.target.value)}
                    helperText={AddError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer's Address</span> : null}
                  />
                </FormControl>
              </div>
            </CardContent>
            {Error ? <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setError(false)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please fill requested form properly
            </Alert> : null}
            <Button variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                handleEdit()
              }} >
              Update Trainer
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '800px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateTrainerList)